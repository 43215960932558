import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { groupBy, uniqBy } from 'lodash'

import { PropsWithChildrenRequired } from 'constants/typings'
import {
  Bid,
  MultipleTenderWinnersInput,
  useChooseMultipleTenderWinnersMutation,
} from 'generated/generated-graphql'
import { useSuccessToast, useErrorToast } from 'utils/toast'

interface ChooseMultipleWinnersContextType {
  selectedBids: Bid[]
  onSelectBid: (bid: Bid) => void
  onSelectMultipleBids: (bids: Bid[]) => void
  onUnselectBid: (bid: Bid) => void
  onUnselectAllBids: () => void
  chooseWinners: () => Promise<void>
  isAvailable: boolean
  isLoadingChooseMultipleTenderWinners: boolean
  setIsAvailable: Dispatch<SetStateAction<boolean>>
  isShowSelectedOnlyFilterActive: boolean
  setIsShowSelectedOnlyFilterActive: Dispatch<SetStateAction<boolean>>
  reset: () => void
}

const ChooseMultipleWinnersContext = createContext(
  {} as ChooseMultipleWinnersContextType
)

export const useChooseMultipleWinners = (): ChooseMultipleWinnersContextType =>
  useContext(ChooseMultipleWinnersContext)

const createChooseMultipleWinnersInput = (
  selectedBids: Bid[]
): MultipleTenderWinnersInput[] => {
  const groupedBids = groupBy(selectedBids, (bid) => bid.tenderId)

  return Object.entries(groupedBids).map(([tenderId, bids]) => ({
    tenderId: Number(tenderId),
    bidIds: bids.map((bid) => bid.id),
  }))
}

const ChooseMultipleWinnersProvider = ({
  children,
}: PropsWithChildrenRequired) => {
  const [selectedBids, setSelectedBids] = useState<Bid[]>([])
  const [isAvailable, setIsAvailable] = useState(true)
  const [isShowSelectedOnlyFilterActive, setIsShowSelectedOnlyFilterActive] =
    useState(false)

  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  const [
    chooseMultipleTenderWinners,
    { loading: isLoadingChooseMultipleTenderWinners },
  ] = useChooseMultipleTenderWinnersMutation()

  const reset = useCallback(() => {
    setSelectedBids([])
    setIsAvailable(false)
    setIsShowSelectedOnlyFilterActive(false)
  }, [])

  const chooseWinners = useCallback(async () => {
    const chooseMultipleWinnersInput =
      createChooseMultipleWinnersInput(selectedBids)

    try {
      await chooseMultipleTenderWinners({
        variables: {
          input: {
            data: chooseMultipleWinnersInput,
          },
        },
        refetchQueries: ['BrandDashboardBids', 'Bids', 'bidsViewFilterOptions'],
      })

      // TODO: add translations
      showSuccessToast({
        description: 'Winning tender bids have been succesfully chosen',
        title: 'Winning bids chosen',
      })
    } catch (error) {
      // TODO: add translations
      showErrorToast({
        description: 'Failed to choose winning bids',
        title: 'Error',
      })
    }
  }, [
    selectedBids,
    chooseMultipleTenderWinners,
    showErrorToast,
    showSuccessToast,
  ])

  const onSelectBid = (bid: Bid) => {
    setSelectedBids((prevState) => [...prevState, bid])
  }

  const onSelectMultipleBids = (bids: Bid[]) => {
    setSelectedBids((prevState) => uniqBy([...prevState, ...bids], 'id'))
  }

  const onUnselectBid = (bid: Bid) => {
    setSelectedBids((prevState) =>
      prevState.filter((bidFromState) => bidFromState.id !== bid.id)
    )
  }

  const onUnselectAllBids = () => {
    setSelectedBids([])
  }

  // Reset selected filters state when selected bids are empty
  useEffect(() => {
    if (selectedBids.length === 0 && isShowSelectedOnlyFilterActive) {
      setIsShowSelectedOnlyFilterActive(false)
    }
  }, [isShowSelectedOnlyFilterActive, selectedBids.length])

  const contextValue = useMemo<ChooseMultipleWinnersContextType>(
    () => ({
      selectedBids,
      onSelectBid,
      onSelectMultipleBids,
      onUnselectBid,
      onUnselectAllBids,
      chooseWinners,
      isAvailable,
      setIsAvailable,
      isShowSelectedOnlyFilterActive,
      setIsShowSelectedOnlyFilterActive,
      reset,
      isLoadingChooseMultipleTenderWinners,
    }),
    [
      isAvailable,
      isShowSelectedOnlyFilterActive,
      selectedBids,
      chooseWinners,
      reset,
      isLoadingChooseMultipleTenderWinners,
    ]
  )
  return (
    <ChooseMultipleWinnersContext.Provider value={contextValue}>
      {children}
    </ChooseMultipleWinnersContext.Provider>
  )
}

export default ChooseMultipleWinnersProvider
