import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Object: { input: any; output: any; }
};

export type AddBankAccountInput = {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  sortCode: Scalars['String']['input'];
};

export type AddBidComparisonAnalyticInput = {
  bidId: Scalars['Int']['input'];
  tenderId: Scalars['Int']['input'];
};

export type AddBidInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  averageWholesalePrice: Scalars['Float']['input'];
  brandId: Scalars['Int']['input'];
  files: Array<FileInput>;
  freeStockNotes?: InputMaybe<Scalars['String']['input']>;
  freeStockUnitsAmount?: InputMaybe<Scalars['Int']['input']>;
  isAnonymous: Scalars['Boolean']['input'];
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  listingFeeNotes?: InputMaybe<Scalars['String']['input']>;
  listingFeeTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  otherSupport?: InputMaybe<Scalars['String']['input']>;
  packagingType: Scalars['String']['input'];
  posBudgetNotes?: InputMaybe<Scalars['String']['input']>;
  posBudgetTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  productCategoryId: Scalars['Int']['input'];
  productName: Scalars['String']['input'];
  productUrl?: InputMaybe<Scalars['String']['input']>;
  retroDiscountAmountPerUnit?: InputMaybe<Scalars['Float']['input']>;
  retroDiscountNotes?: InputMaybe<Scalars['String']['input']>;
  subscriptionTier?: InputMaybe<SubscriptionTier>;
  successFee?: InputMaybe<Scalars['Float']['input']>;
  supportsFreeStock?: InputMaybe<Scalars['Boolean']['input']>;
  supportsListingFee?: InputMaybe<Scalars['Boolean']['input']>;
  supportsPosBudget?: InputMaybe<Scalars['Boolean']['input']>;
  supportsRetroDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  tenderId: Scalars['Int']['input'];
  unitType: Scalars['String']['input'];
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type AddBidsToMultipleTendersInput = {
  bidData: AddMultipleBidInput;
  tenderIds: Array<Scalars['Int']['input']>;
};

export type AddMessageInput = {
  bidIds: Array<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type AddMultipleBidInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  averageWholesalePrice: Scalars['Float']['input'];
  brandId: Scalars['Int']['input'];
  files: Array<FileInput>;
  freeStockNotes?: InputMaybe<Scalars['String']['input']>;
  freeStockUnitsAmount?: InputMaybe<Scalars['Int']['input']>;
  isAnonymous: Scalars['Boolean']['input'];
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  listingFeeNotes?: InputMaybe<Scalars['String']['input']>;
  listingFeeTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  otherSupport?: InputMaybe<Scalars['String']['input']>;
  packagingType: Scalars['String']['input'];
  posBudgetNotes?: InputMaybe<Scalars['String']['input']>;
  posBudgetTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  productCategoryId: Scalars['Int']['input'];
  productName: Scalars['String']['input'];
  productUrl?: InputMaybe<Scalars['String']['input']>;
  retroDiscountAmountPerUnit?: InputMaybe<Scalars['Float']['input']>;
  retroDiscountNotes?: InputMaybe<Scalars['String']['input']>;
  subscriptionTier?: InputMaybe<SubscriptionTier>;
  successFee?: InputMaybe<Scalars['Float']['input']>;
  supportsFreeStock?: InputMaybe<Scalars['Boolean']['input']>;
  supportsListingFee?: InputMaybe<Scalars['Boolean']['input']>;
  supportsPosBudget?: InputMaybe<Scalars['Boolean']['input']>;
  supportsRetroDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  unitType: Scalars['String']['input'];
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type AddProductCategorySubscriptionInput = {
  brandId: Scalars['Int']['input'];
  stripeProductId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type AddProductTemplateInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  averageWholesalePrice: Scalars['Float']['input'];
  brandId: Scalars['Int']['input'];
  files?: InputMaybe<Array<FileInput>>;
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  packagingType: Scalars['String']['input'];
  productCategoryId: Scalars['Int']['input'];
  productName: Scalars['String']['input'];
  productUrl?: InputMaybe<Scalars['String']['input']>;
  unitType: Scalars['String']['input'];
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type AddPromoDealInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  averageWholesalePrice: Scalars['Float']['input'];
  brandId: Scalars['Int']['input'];
  files?: InputMaybe<Array<FileInput>>;
  freeStockNotes?: InputMaybe<Scalars['String']['input']>;
  freeStockUnitsAmount?: InputMaybe<Scalars['Int']['input']>;
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible: Scalars['Boolean']['input'];
  listingFeeNotes?: InputMaybe<Scalars['String']['input']>;
  listingFeeTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  otherSupport?: InputMaybe<Scalars['String']['input']>;
  packagingType: Scalars['String']['input'];
  posBudgetNotes?: InputMaybe<Scalars['String']['input']>;
  posBudgetTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  productCategoryId: Scalars['Int']['input'];
  productName: Scalars['String']['input'];
  productUrl?: InputMaybe<Scalars['String']['input']>;
  region: Region;
  retroDiscountAmountPerUnit?: InputMaybe<Scalars['Float']['input']>;
  retroDiscountNotes?: InputMaybe<Scalars['String']['input']>;
  status: PromoDealStatus;
  supportsFreeStock?: InputMaybe<Scalars['Boolean']['input']>;
  supportsListingFee?: InputMaybe<Scalars['Boolean']['input']>;
  supportsPosBudget?: InputMaybe<Scalars['Boolean']['input']>;
  supportsRetroDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  unitType: Scalars['String']['input'];
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type AddTenderDraftInput = {
  estimatedVolume?: InputMaybe<Scalars['Int']['input']>;
  files: Array<FileInput>;
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  listingEndsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  listingStartsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  listingTypeId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  packagingType?: InputMaybe<Scalars['String']['input']>;
  productCategoryId: Scalars['Int']['input'];
  supportDetails?: InputMaybe<Scalars['String']['input']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  tenderDetails?: InputMaybe<Scalars['String']['input']>;
  tenderEndsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  tenderStartsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  unitType?: InputMaybe<Scalars['String']['input']>;
  venueId: Scalars['Int']['input'];
  volumeDetails?: InputMaybe<Scalars['String']['input']>;
  volumeUnits?: InputMaybe<Scalars['Int']['input']>;
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type AddTenderInput = {
  estimatedVolume: Scalars['Int']['input'];
  files: Array<FileInput>;
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  listingEndsAtUtc: Scalars['Date']['input'];
  listingStartsAtUtc: Scalars['Date']['input'];
  listingTypeId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  packagingType: Scalars['String']['input'];
  productCategoryId: Scalars['Int']['input'];
  supportDetails?: InputMaybe<Scalars['String']['input']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  tenderDetails?: InputMaybe<Scalars['String']['input']>;
  tenderEndsAtUtc: Scalars['Date']['input'];
  tenderStartsAtUtc: Scalars['Date']['input'];
  unitType: Scalars['String']['input'];
  venueId: Scalars['Int']['input'];
  volumeDetails?: InputMaybe<Scalars['String']['input']>;
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type Address = {
  __typename?: 'Address';
  administrativeAreLevel2ShortName?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel1ShortName?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']['output']>;
  coordinates: Point;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  placeId: Scalars['String']['output'];
  postCode: Scalars['String']['output'];
};

export type AddressByPlaceIdInput = {
  googlePlacesSessionToken: Scalars['String']['input'];
  placeId: Scalars['String']['input'];
  region: Region;
};

export type AvatarUrlInput = {
  bucketKey: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type AwrsDetails = {
  __typename?: 'AwrsDetails';
  awrsCompanyName?: Maybe<Scalars['String']['output']>;
  awrsDeregistrationDate?: Maybe<Scalars['String']['output']>;
  awrsNumber?: Maybe<Scalars['String']['output']>;
  awrsPlaceBusiness?: Maybe<Scalars['String']['output']>;
  awrsRegistrationDate?: Maybe<Scalars['String']['output']>;
  awrsStatus?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  sortCode: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type BespokeCheckData = {
  __typename?: 'BespokeCheckData';
  pricePerMonth: Scalars['Float']['output'];
  successFee: Scalars['Float']['output'];
};

export type Bid = {
  __typename?: 'Bid';
  areWholesalersCompatible?: Maybe<Scalars['Boolean']['output']>;
  avatarBucketKey?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  averageWholesalePrice?: Maybe<Scalars['Float']['output']>;
  bidRatingFeedbackValues?: Maybe<Array<BidRatingFeedbackValue>>;
  brand?: Maybe<Brand>;
  brandId: Scalars['Int']['output'];
  createdAtUtc?: Maybe<Scalars['Date']['output']>;
  files?: Maybe<Array<File>>;
  freeStockNotes?: Maybe<Scalars['String']['output']>;
  freeStockUnitsAmount?: Maybe<Scalars['Int']['output']>;
  hasOtherBidsForThisVenue?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isAnonymous: Scalars['Boolean']['output'];
  isAnyWholesalerCompatible: Scalars['Boolean']['output'];
  isDirectSupplyCompatible: Scalars['Boolean']['output'];
  isFreeStockProvided?: Maybe<Scalars['Boolean']['output']>;
  isFreeStockReceived?: Maybe<Scalars['Boolean']['output']>;
  isListingFeeProvided?: Maybe<Scalars['Boolean']['output']>;
  isListingFeeReceived?: Maybe<Scalars['Boolean']['output']>;
  isPosBudgetProvided?: Maybe<Scalars['Boolean']['output']>;
  isPosBudgetReceived?: Maybe<Scalars['Boolean']['output']>;
  isRatingOutdated?: Maybe<Scalars['Boolean']['output']>;
  isRetroDiscountProvided?: Maybe<Scalars['Boolean']['output']>;
  isRetroDiscountReceived?: Maybe<Scalars['Boolean']['output']>;
  listingFeeNotes?: Maybe<Scalars['String']['output']>;
  listingFeeTotalAmount?: Maybe<Scalars['Float']['output']>;
  messagesCount?: Maybe<Scalars['Int']['output']>;
  netPrice?: Maybe<Scalars['Float']['output']>;
  otherSupport?: Maybe<Scalars['String']['output']>;
  packagingType?: Maybe<Scalars['String']['output']>;
  posBudgetNotes?: Maybe<Scalars['String']['output']>;
  posBudgetTotalAmount?: Maybe<Scalars['Float']['output']>;
  productCategory?: Maybe<ProductCategory>;
  productCategoryId?: Maybe<Scalars['Int']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productUrl?: Maybe<Scalars['String']['output']>;
  ratedAtUtc?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  ratingComment?: Maybe<Scalars['String']['output']>;
  region: Region;
  retroDiscountAmountPerUnit?: Maybe<Scalars['Float']['output']>;
  retroDiscountNotes?: Maybe<Scalars['String']['output']>;
  status: BidStatus;
  subscriptionTier: SubscriptionTier;
  successFee?: Maybe<Scalars['Float']['output']>;
  successFeeInvoiceId?: Maybe<Scalars['String']['output']>;
  supportsFreeStock?: Maybe<Scalars['Boolean']['output']>;
  supportsListingFee?: Maybe<Scalars['Boolean']['output']>;
  supportsPosBudget?: Maybe<Scalars['Boolean']['output']>;
  supportsRetroDiscount?: Maybe<Scalars['Boolean']['output']>;
  tender: Tender;
  tenderId: Scalars['Int']['output'];
  totalSupportValue?: Maybe<Scalars['Float']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
  updatedAtUtc?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  volumeUnits?: Maybe<Scalars['Int']['output']>;
  wholesalers?: Maybe<Array<Wholesaler>>;
};

export type BidRatingFeedbackValue = {
  __typename?: 'BidRatingFeedbackValue';
  ratingFeedbackValueId: Scalars['Int']['output'];
};

export enum BidStatus {
  Deleted = 'DELETED',
  Lost = 'LOST',
  New = 'NEW',
  Won = 'WON'
}

export enum BidSupportType {
  FreeStock = 'FREE_STOCK',
  ListingFee = 'LISTING_FEE',
  PosBudget = 'POS_BUDGET',
  RetroDiscount = 'RETRO_DISCOUNT'
}

export type Bids = {
  __typename?: 'Bids';
  data: Array<Bid>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BidsFilterOptionsInput = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  tenderId?: InputMaybe<Scalars['Int']['input']>;
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type BidsInput = {
  filter?: InputMaybe<BidsViewFiltersInput>;
  pagination: OffsetPaginationInput;
  sort?: InputMaybe<BidsSortInput>;
};

export enum BidsOrderByEnum {
  BidIds = 'BID_IDS',
  EstimatedVolume = 'ESTIMATED_VOLUME',
  NetPrice = 'NET_PRICE',
  Price = 'PRICE',
  Rating = 'RATING',
  RecentlyAdded = 'RECENTLY_ADDED',
  Status = 'STATUS',
  SupportProvided = 'SUPPORT_PROVIDED',
  SupportReceived = 'SUPPORT_RECEIVED',
  TimeLeft = 'TIME_LEFT',
  TotalSupport = 'TOTAL_SUPPORT',
  WholesalerCompatibility = 'WHOLESALER_COMPATIBILITY'
}

export type BidsSortInput = {
  bidIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isSupportProvidedFirst?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<BidsOrderByEnum>;
  orderDirection?: InputMaybe<SortDirectionEnum>;
  supportType?: InputMaybe<BidSupportType>;
};

export type BidsViewFiltersInput = {
  bidStatus?: InputMaybe<BidStatus>;
  brandIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  excludeBrandId?: InputMaybe<Scalars['Int']['input']>;
  excludeWinningBids?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<PriceRangeOption>;
  productName?: InputMaybe<Array<Scalars['String']['input']>>;
  tenderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  venueIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  wholesalerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Brand = {
  __typename?: 'Brand';
  avatarBucketKey?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  awrsCompanyName?: Maybe<Scalars['String']['output']>;
  awrsDeregistrationDate?: Maybe<Scalars['String']['output']>;
  awrsNumber?: Maybe<Scalars['String']['output']>;
  awrsPlaceBusiness?: Maybe<Scalars['String']['output']>;
  awrsRegistrationDate?: Maybe<Scalars['String']['output']>;
  awrsStatus?: Maybe<Scalars['String']['output']>;
  canSeeOtherBids: Scalars['Boolean']['output'];
  complianceCheckDate?: Maybe<Scalars['String']['output']>;
  complianceConfirmation?: Maybe<Scalars['String']['output']>;
  complianceNextDate?: Maybe<Scalars['String']['output']>;
  complianceRiskLevel?: Maybe<Scalars['String']['output']>;
  failedPaymentIntentCounter: Scalars['Int']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  hasBids: Scalars['Boolean']['output'];
  hasProductTemplates: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  isAnonymousBidAllowed?: Maybe<Scalars['Boolean']['output']>;
  isEnterpriseSubscriptionRequestSent: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  productCategories: Array<ProductCategory>;
  region: Region;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripePaymentLink?: Maybe<Scalars['String']['output']>;
  stripeProductPriceId?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionTier: SubscriptionTier;
  successFee: Scalars['Float']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  vatCompanyName?: Maybe<Scalars['String']['output']>;
  vatConsultationNumber?: Maybe<Scalars['String']['output']>;
  vatCountryCode?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
  vatProcessingDate?: Maybe<Scalars['String']['output']>;
  webPageUrl?: Maybe<Scalars['String']['output']>;
};

export enum BrandDashboardBidStatus {
  ActiveBids = 'ACTIVE_BIDS',
  LostBids = 'LOST_BIDS',
  WonBids = 'WON_BIDS'
}

export type BrandDashboardFilterOptionsInput = {
  status?: InputMaybe<BrandDashboardBidStatus>;
};

export type BrandDashboardMetric = {
  __typename?: 'BrandDashboardMetric';
  label: BrandDashboardMetricLabel;
  type: DashboardMetricType;
  value: Scalars['String']['output'];
};

export enum BrandDashboardMetricLabel {
  CurrentOfferedSupport = 'CURRENT_OFFERED_SUPPORT',
  NewTendersPublished = 'NEW_TENDERS_PUBLISHED',
  PossibleTotalMerchandiseValue = 'POSSIBLE_TOTAL_MERCHANDISE_VALUE',
  TotalEstimatedVolume = 'TOTAL_ESTIMATED_VOLUME',
  TotalMerchandiseValue = 'TOTAL_MERCHANDISE_VALUE',
  TotalSupport = 'TOTAL_SUPPORT'
}

export type BrandDashboardWonBidsSortInput = {
  isProvidedFirst: Scalars['Boolean']['input'];
  sortBySupport: BidSupportType;
};

export type BrandSettings = {
  __typename?: 'BrandSettings';
  brandId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isNewTenderInAppNotificationEnabled: Scalars['Boolean']['output'];
  isNewTenderMailNotificationEnabled: Scalars['Boolean']['output'];
  isRatingProvidedInAppNotificationEnabled: Scalars['Boolean']['output'];
  isRatingProvidedMailNotificationEnabled: Scalars['Boolean']['output'];
  isTenderUpdatedInAppNotificationEnabled: Scalars['Boolean']['output'];
  isTenderUpdatedMailNotificationEnabled: Scalars['Boolean']['output'];
};

export type Brands = {
  __typename?: 'Brands';
  data: Array<Brand>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CancelSubscriptionInput = {
  brandId: Scalars['Int']['input'];
  region: Region;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionNewInAppInput = {
  brandId: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CancelSubscriptionNewRetoolInput = {
  brandId: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type ChangeEmailInput = {
  email: Scalars['String']['input'];
};

export type ChooseMultipleTenderWinnersInput = {
  data: Array<MultipleTenderWinnersInput>;
};

export type ChooseWinnerInput = {
  bidIds: Array<Scalars['Int']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  tenderId: Scalars['Int']['input'];
};

export type CompanyVatDetails = {
  __typename?: 'CompanyVatDetails';
  vatCompanyName: Scalars['String']['output'];
  vatConsultationNumber: Scalars['String']['output'];
  vatCountryCode: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
  vatProcessingDate: Scalars['String']['output'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['String']['output'];
  lng: Scalars['String']['output'];
};

export type CoordinatesInput = {
  lat: Scalars['String']['input'];
  lng: Scalars['String']['input'];
};

export type CreateBankTransferSubscriptionInput = {
  brandId: Scalars['Int']['input'];
  pricePerMonth: Scalars['Int']['input'];
  successFee: Scalars['Float']['input'];
};

export type CreateBespokePaymentLinkInput = {
  brandId: Scalars['Int']['input'];
  pricePerMonth: Scalars['Int']['input'];
  successFee: Scalars['Float']['input'];
};

export type CreateStripeCheckoutInput = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  firebaseId: Scalars['String']['input'];
  returnUrl?: InputMaybe<Scalars['String']['input']>;
  subscriptionTier: SubscriptionTier;
};

export type CreateStripeCheckoutNewInput = {
  brandId: Scalars['Int']['input'];
  firebaseId: Scalars['String']['input'];
  returnUrl?: InputMaybe<Scalars['String']['input']>;
  stripeProductId: Scalars['String']['input'];
};

export type CursorNotifications = CursorPagination & {
  __typename?: 'CursorNotifications';
  edges: Array<NotificationEdge>;
  pageInfo: CursorPageInfo;
  totalCount: Scalars['Int']['output'];
  unreadTotalCount: Scalars['Int']['output'];
};

export type CursorNotificationsInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  category: NotificationCategory;
  first: Scalars['Int']['input'];
  toId: Scalars['Int']['input'];
  toType: NotificationTargetType;
};

export type CursorPageInfo = {
  __typename?: 'CursorPageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type CursorPagination = {
  edges: Array<Edge>;
  pageInfo: CursorPageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CursorPaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
};

export enum DashboardMetricType {
  Global = 'GLOBAL',
  Personalised = 'PERSONALISED'
}

export type DeleteBidInput = {
  bidId: Scalars['Int']['input'];
};

export type DeleteProductTemplateInput = {
  brandId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type EditBankAccountInput = {
  accountName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  sortCode?: InputMaybe<Scalars['String']['input']>;
};

export type EditBidInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  files: Array<FileInput>;
  freeStockNotes?: InputMaybe<Scalars['String']['input']>;
  freeStockUnitsAmount?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  listingFeeNotes?: InputMaybe<Scalars['String']['input']>;
  listingFeeTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  otherSupport?: InputMaybe<Scalars['String']['input']>;
  posBudgetNotes?: InputMaybe<Scalars['String']['input']>;
  posBudgetTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  retroDiscountAmountPerUnit?: InputMaybe<Scalars['Float']['input']>;
  retroDiscountNotes?: InputMaybe<Scalars['String']['input']>;
  supportsFreeStock?: InputMaybe<Scalars['Boolean']['input']>;
  supportsListingFee?: InputMaybe<Scalars['Boolean']['input']>;
  supportsPosBudget?: InputMaybe<Scalars['Boolean']['input']>;
  supportsRetroDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type EditBidSupportStatusInput = {
  bidId: Scalars['Int']['input'];
  brandId?: InputMaybe<Scalars['Int']['input']>;
  supportToChange: BidSupportType;
  tenderId: Scalars['Int']['input'];
  venueId?: InputMaybe<Scalars['Int']['input']>;
};

export type EditBrandInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  awrsCompanyName?: InputMaybe<Scalars['String']['input']>;
  awrsDeregistrationDate?: InputMaybe<Scalars['String']['input']>;
  awrsNumber?: InputMaybe<Scalars['String']['input']>;
  awrsPlaceBusiness?: InputMaybe<Scalars['String']['input']>;
  awrsRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  awrsStatus?: InputMaybe<Scalars['String']['input']>;
  canSeeOtherBids?: InputMaybe<Scalars['Boolean']['input']>;
  complianceCheckDate?: InputMaybe<Scalars['String']['input']>;
  complianceConfirmation?: InputMaybe<Scalars['String']['input']>;
  complianceNextDate?: InputMaybe<Scalars['String']['input']>;
  complianceRiskLevel?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  isAnonymousBidAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  productCategoriesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  vatCompanyName?: InputMaybe<Scalars['String']['input']>;
  vatConsultationNumber?: InputMaybe<Scalars['String']['input']>;
  vatCountryCode?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatProcessingDate?: InputMaybe<Scalars['String']['input']>;
  webPageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EditBrandSettingsInput = {
  brandId: Scalars['Int']['input'];
  isNewTenderInAppNotificationEnabled: Scalars['Boolean']['input'];
  isNewTenderMailNotificationEnabled: Scalars['Boolean']['input'];
  isRatingProvidedInAppNotificationEnabled: Scalars['Boolean']['input'];
  isRatingProvidedMailNotificationEnabled: Scalars['Boolean']['input'];
  isTenderUpdatedInAppNotificationEnabled: Scalars['Boolean']['input'];
  isTenderUpdatedMailNotificationEnabled: Scalars['Boolean']['input'];
};

export type EditProductTemplateInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  averageWholesalePrice: Scalars['Float']['input'];
  brandId: Scalars['Int']['input'];
  files: Array<FileInput>;
  id: Scalars['Int']['input'];
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  packagingType: Scalars['String']['input'];
  productCategoryId: Scalars['Int']['input'];
  productName: Scalars['String']['input'];
  productUrl?: InputMaybe<Scalars['String']['input']>;
  unitType: Scalars['String']['input'];
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type EditPromoDealInput = {
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  averageWholesalePrice?: InputMaybe<Scalars['Float']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  files: Array<FileInput>;
  freeStockNotes?: InputMaybe<Scalars['String']['input']>;
  freeStockUnitsAmount?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible: Scalars['Boolean']['input'];
  listingFeeNotes?: InputMaybe<Scalars['String']['input']>;
  listingFeeTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  otherSupport?: InputMaybe<Scalars['String']['input']>;
  packagingType?: InputMaybe<Scalars['String']['input']>;
  posBudgetNotes?: InputMaybe<Scalars['String']['input']>;
  posBudgetTotalAmount?: InputMaybe<Scalars['Float']['input']>;
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  productUrl?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Region>;
  retroDiscountAmountPerUnit?: InputMaybe<Scalars['Float']['input']>;
  retroDiscountNotes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PromoDealStatus>;
  supportsFreeStock?: InputMaybe<Scalars['Boolean']['input']>;
  supportsListingFee?: InputMaybe<Scalars['Boolean']['input']>;
  supportsPosBudget?: InputMaybe<Scalars['Boolean']['input']>;
  supportsRetroDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  unitType?: InputMaybe<Scalars['String']['input']>;
  volumeUnits?: InputMaybe<Scalars['Int']['input']>;
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type EditTenderDraftInput = {
  estimatedVolume?: InputMaybe<Scalars['Int']['input']>;
  files: Array<FileInput>;
  id: Scalars['Int']['input'];
  isAnyWholesalerCompatible: Scalars['Boolean']['input'];
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  listingEndsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  listingStartsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  listingTypeId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  packagingType?: InputMaybe<Scalars['String']['input']>;
  productCategoryId: Scalars['Int']['input'];
  supportDetails?: InputMaybe<Scalars['String']['input']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  tenderDetails?: InputMaybe<Scalars['String']['input']>;
  tenderEndsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  tenderStartsAtUtc?: InputMaybe<Scalars['Date']['input']>;
  unitType?: InputMaybe<Scalars['String']['input']>;
  volumeDetails?: InputMaybe<Scalars['String']['input']>;
  volumeUnits?: InputMaybe<Scalars['Int']['input']>;
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type EditTenderInput = {
  estimatedVolume: Scalars['Int']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  files: Array<FileInput>;
  id: Scalars['Int']['input'];
  isAnyWholesalerCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  listingEndsAtUtc: Scalars['Date']['input'];
  listingStartsAtUtc: Scalars['Date']['input'];
  listingTypeId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  packagingType: Scalars['String']['input'];
  productCategoryId: Scalars['Int']['input'];
  status: TenderStatus;
  supportDetails?: InputMaybe<Scalars['String']['input']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  tenderDetails?: InputMaybe<Scalars['String']['input']>;
  tenderEndsAtUtc: Scalars['Date']['input'];
  tenderStartsAtUtc: Scalars['Date']['input'];
  unitType: Scalars['String']['input'];
  volumeDetails?: InputMaybe<Scalars['String']['input']>;
  volumeUnits: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type EditTenderPreferenceInput = {
  id: Scalars['Int']['input'];
  isAnyWholesalerCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  isDirectSupplyCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  supportDetails?: InputMaybe<Scalars['String']['input']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  venueId: Scalars['Int']['input'];
  wholesalersIds: Array<Scalars['Int']['input']>;
};

export type EditVenueInput = {
  address: GoogleAddressInput;
  avatarBucketKey?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  googlePlacesSessionToken: Scalars['String']['input'];
  groupName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  venueCategoryId: Scalars['Int']['input'];
  webPageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EditVenueSettingsInput = {
  isBidUpdatedInAppNotificationEnabled: Scalars['Boolean']['input'];
  isBidUpdatedMailNotificationEnabled: Scalars['Boolean']['input'];
  isMyTenderEndedInAppNotificationEnabled: Scalars['Boolean']['input'];
  isMyTenderEndedMailNotificationEnabled: Scalars['Boolean']['input'];
  isNewBidsInAppNotificationEnabled: Scalars['Boolean']['input'];
  isNewBidsMailNotificationEnabled: Scalars['Boolean']['input'];
  isNewTenderInAppNotificationEnabled: Scalars['Boolean']['input'];
  isNewTenderMailNotificationEnabled: Scalars['Boolean']['input'];
  isReTenderInAppNotificationEnabled: Scalars['Boolean']['input'];
  isReTenderMailNotificationEnabled: Scalars['Boolean']['input'];
  venueId: Scalars['Int']['input'];
};

export enum EmailType {
  GetInTouch = 'GetInTouch',
  RequestDemo = 'RequestDemo',
  RequestEnterpriseSubscription = 'RequestEnterpriseSubscription',
  RequestPromoDeal = 'RequestPromoDeal'
}

export enum Feature {
  SalesDemo = 'SALES_DEMO'
}

export type File = {
  __typename?: 'File';
  bucketKey: Scalars['String']['output'];
  context: FileContext;
  downloadUrl: Scalars['String']['output'];
  fileSizeInKib: Scalars['Float']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export enum FileContext {
  BidDocument = 'BID_DOCUMENT',
  BidPosDocument = 'BID_POS_DOCUMENT',
  ProductAvatar = 'PRODUCT_AVATAR',
  ProductDocument = 'PRODUCT_DOCUMENT',
  TenderDocument = 'TENDER_DOCUMENT',
  TenderSalesVolumeData = 'TENDER_SALES_VOLUME_DATA',
  UserAvatar = 'USER_AVATAR'
}

export type FileInput = {
  bucketKey: Scalars['String']['input'];
  context: FileContext;
  fileSizeInKib: Scalars['Float']['input'];
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Filter = {
  __typename?: 'Filter';
  label: Scalars['String']['output'];
  options: Array<FilterOption>;
};

export type FilterOption = {
  __typename?: 'FilterOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GetAwrsDetailsInput = {
  awrsNumber: Scalars['String']['input'];
};

export type GetCompanyVatDetailsInput = {
  vatNumber: Scalars['String']['input'];
};

export type GetDataFromCompanyNumberInput = {
  companyNumber: Scalars['String']['input'];
};

export type GoogleAddress = {
  __typename?: 'GoogleAddress';
  administrativeAreLevel2ShortName?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel1?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel1ShortName?: Maybe<Scalars['String']['output']>;
  administrativeAreaLevel2?: Maybe<Scalars['String']['output']>;
  coordinates: Coordinates;
  country?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  placeId: Scalars['String']['output'];
  placeName: Scalars['String']['output'];
  postCode?: Maybe<Scalars['String']['output']>;
};

export type GoogleAddressInput = {
  administrativeAreLevel2ShortName?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel1?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel1ShortName?: InputMaybe<Scalars['String']['input']>;
  administrativeAreaLevel2?: InputMaybe<Scalars['String']['input']>;
  coordinates: CoordinatesInput;
  country?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  placeId: Scalars['String']['input'];
  placeName: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
};

export type GoogleLocationByCoordinates = {
  __typename?: 'GoogleLocationByCoordinates';
  country?: Maybe<Scalars['String']['output']>;
};

export type HelloInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['Int']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  pdfUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type ListingType = {
  __typename?: 'ListingType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  regions: Array<Region>;
  suggestedListingLengthInMonths: Scalars['Int']['output'];
  suggestedTenderLengthInMonths: Scalars['Int']['output'];
};

export type LiveStats = {
  __typename?: 'LiveStats';
  activeBidGrossMerchandiseValue: Scalars['Float']['output'];
  activeTenders: Scalars['Int']['output'];
};

export type LocationByCoordinatesInput = {
  latitude: Scalars['String']['input'];
  longitude: Scalars['String']['input'];
};

export type MarkAllNotificationsAsReadInput = {
  category: NotificationCategory;
  toId: Scalars['Int']['input'];
  toType: NotificationTargetType;
};

export type Message = {
  __typename?: 'Message';
  bidId: Scalars['Int']['output'];
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['Int']['output']>;
  createdAtUtc: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updatedAtUtc?: Maybe<Scalars['String']['output']>;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export type MultipleTenderWinnersInput = {
  bidIds: Array<Scalars['Int']['input']>;
  tenderId: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBankAccount: BankAccount;
  addBid: Bid;
  addBidComparisonAnalytic: Scalars['Boolean']['output'];
  addBidsToMultipleTenders: Array<Bid>;
  addMessage: Array<Message>;
  addProductCategorySubscription: Scalars['Boolean']['output'];
  addProductTemplate: ProductTemplate;
  addPromoDeal: PromoDeal;
  addTender: Tender;
  addTenderDraft: TenderDraft;
  addUserActionLog: UserActionLog;
  cancelSubscription: Scalars['Boolean']['output'];
  cancelSubscriptionNewInApp: Scalars['Boolean']['output'];
  cancelSubscriptionNewRetool: Scalars['Boolean']['output'];
  changeEmail: User;
  chooseMultipleTenderWinners: Array<Tender>;
  chooseWinner: Tender;
  cleanupSalesDemoData: Scalars['Boolean']['output'];
  confirmChangeEmail: Scalars['Boolean']['output'];
  createBankTransferSubscription: Scalars['Boolean']['output'];
  createBespokePaymentLink: Scalars['String']['output'];
  createDatabaseBackup: Scalars['Boolean']['output'];
  createStripeCheckout: Scalars['String']['output'];
  createStripeCheckoutNew: Scalars['String']['output'];
  deleteBidById: Scalars['Boolean']['output'];
  deleteProductTemplateById: Scalars['Boolean']['output'];
  deletePromoDealById: Scalars['Boolean']['output'];
  deleteTenderById: Scalars['Boolean']['output'];
  deleteTenderDraftById: Scalars['Boolean']['output'];
  editBankAccount: BankAccount;
  editBid: Bid;
  editBidSupportStatus: Bid;
  editBrand: Brand;
  editBrandSettings: BrandSettings;
  editProductTemplate: ProductTemplate;
  editPromoDeal: PromoDeal;
  editTender: Tender;
  editTenderDraft: TenderDraft;
  editTenderPreference: TenderPreference;
  editVenue: Venue;
  editVenueSettings: VenueSettings;
  generateUploadUrls: Array<UploadUrlItem>;
  markAllNotificationsAsRead: Array<Notification>;
  markNotificationAsRead: Notification;
  rateBid: Bid;
  removeProductCategorySubscription: Scalars['Boolean']['output'];
  requestChangeEmail: Scalars['Boolean']['output'];
  requestEmail: Scalars['Boolean']['output'];
  requestSalesDemoBids: Array<Bid>;
  retoolChangeBidStatus: Bid;
  retoolChangeTenderAllBidsStatus: Array<Bid>;
  retoolChangeTenderStatus: Tender;
  retoolEditBrand: Brand;
  salesDemoSignUp: Scalars['Boolean']['output'];
  sampleRequest: Scalars['Boolean']['output'];
  sendBankTransferInvoiceNotPaidEmail: Scalars['Boolean']['output'];
  sendDailyNewBidsReport: Scalars['Boolean']['output'];
  sendDailyNewTenderBrandReport: Scalars['Boolean']['output'];
  sendDailyRatingReport: Scalars['Boolean']['output'];
  sendReTenderEmail: Scalars['Boolean']['output'];
  sendSelectWinnerEmail: Scalars['Boolean']['output'];
  sendTenderEndsInTwoDaysEmail: Scalars['Boolean']['output'];
  signInAsUser: Scalars['String']['output'];
  signUp: Scalars['String']['output'];
  supportCalculator: Scalars['Boolean']['output'];
  syncTenderStatuses: Scalars['Boolean']['output'];
  updateCompanyBillingData: User;
  updateSubscription: Scalars['Boolean']['output'];
  upgradeProductCategorySubscription: Scalars['Boolean']['output'];
  upgradeToBespoke: UpgradeToBespokeData;
};


export type MutationAddBankAccountArgs = {
  input: AddBankAccountInput;
};


export type MutationAddBidArgs = {
  input: AddBidInput;
};


export type MutationAddBidComparisonAnalyticArgs = {
  input: AddBidComparisonAnalyticInput;
};


export type MutationAddBidsToMultipleTendersArgs = {
  input: AddBidsToMultipleTendersInput;
};


export type MutationAddMessageArgs = {
  input: AddMessageInput;
};


export type MutationAddProductCategorySubscriptionArgs = {
  input: AddProductCategorySubscriptionInput;
};


export type MutationAddProductTemplateArgs = {
  input: AddProductTemplateInput;
};


export type MutationAddPromoDealArgs = {
  input: AddPromoDealInput;
};


export type MutationAddTenderArgs = {
  input: AddTenderInput;
};


export type MutationAddTenderDraftArgs = {
  input: AddTenderDraftInput;
};


export type MutationAddUserActionLogArgs = {
  input: UserActionLogInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationCancelSubscriptionNewInAppArgs = {
  input: CancelSubscriptionNewInAppInput;
};


export type MutationCancelSubscriptionNewRetoolArgs = {
  input: CancelSubscriptionNewRetoolInput;
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationChooseMultipleTenderWinnersArgs = {
  input: ChooseMultipleTenderWinnersInput;
};


export type MutationChooseWinnerArgs = {
  input: ChooseWinnerInput;
};


export type MutationCleanupSalesDemoDataArgs = {
  token: Scalars['String']['input'];
};


export type MutationConfirmChangeEmailArgs = {
  changeEmailToken: Scalars['String']['input'];
};


export type MutationCreateBankTransferSubscriptionArgs = {
  input: CreateBankTransferSubscriptionInput;
};


export type MutationCreateBespokePaymentLinkArgs = {
  input: CreateBespokePaymentLinkInput;
};


export type MutationCreateDatabaseBackupArgs = {
  token: Scalars['String']['input'];
};


export type MutationCreateStripeCheckoutArgs = {
  input: CreateStripeCheckoutInput;
};


export type MutationCreateStripeCheckoutNewArgs = {
  input: CreateStripeCheckoutNewInput;
};


export type MutationDeleteBidByIdArgs = {
  bidId: Scalars['Int']['input'];
};


export type MutationDeleteProductTemplateByIdArgs = {
  input: DeleteProductTemplateInput;
};


export type MutationDeletePromoDealByIdArgs = {
  promoDealId: Scalars['Int']['input'];
};


export type MutationDeleteTenderByIdArgs = {
  tenderId: Scalars['Int']['input'];
};


export type MutationDeleteTenderDraftByIdArgs = {
  tenderDraftId: Scalars['Int']['input'];
};


export type MutationEditBankAccountArgs = {
  input: EditBankAccountInput;
};


export type MutationEditBidArgs = {
  input: EditBidInput;
};


export type MutationEditBidSupportStatusArgs = {
  input: EditBidSupportStatusInput;
};


export type MutationEditBrandArgs = {
  input: EditBrandInput;
};


export type MutationEditBrandSettingsArgs = {
  input: EditBrandSettingsInput;
};


export type MutationEditProductTemplateArgs = {
  input: EditProductTemplateInput;
};


export type MutationEditPromoDealArgs = {
  input: EditPromoDealInput;
};


export type MutationEditTenderArgs = {
  input: EditTenderInput;
};


export type MutationEditTenderDraftArgs = {
  input: EditTenderDraftInput;
};


export type MutationEditTenderPreferenceArgs = {
  input: EditTenderPreferenceInput;
};


export type MutationEditVenueArgs = {
  input: EditVenueInput;
};


export type MutationEditVenueSettingsArgs = {
  input: EditVenueSettingsInput;
};


export type MutationGenerateUploadUrlsArgs = {
  fileNames: Array<Scalars['String']['input']>;
};


export type MutationMarkAllNotificationsAsReadArgs = {
  input: MarkAllNotificationsAsReadInput;
};


export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRateBidArgs = {
  input: RateBidInput;
};


export type MutationRemoveProductCategorySubscriptionArgs = {
  input: RemoveProductCategorySubscriptionInput;
};


export type MutationRequestChangeEmailArgs = {
  emailToChange: Scalars['String']['input'];
};


export type MutationRequestEmailArgs = {
  input: RequestEmailInput;
};


export type MutationRequestSalesDemoBidsArgs = {
  tenderId: Scalars['Int']['input'];
};


export type MutationRetoolChangeBidStatusArgs = {
  input: RetoolChangeBidStatusInput;
};


export type MutationRetoolChangeTenderAllBidsStatusArgs = {
  input: RetoolChangeTenderAllBidsStatusInput;
};


export type MutationRetoolChangeTenderStatusArgs = {
  input: RetoolChangeTenderStatusInput;
};


export type MutationRetoolEditBrandArgs = {
  input: EditBrandInput;
};


export type MutationSalesDemoSignUpArgs = {
  input: SignUpInput;
};


export type MutationSampleRequestArgs = {
  input: SampleRequestInput;
};


export type MutationSendBankTransferInvoiceNotPaidEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendDailyNewBidsReportArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendDailyNewTenderBrandReportArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendDailyRatingReportArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendReTenderEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendSelectWinnerEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationSendTenderEndsInTwoDaysEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationSignInAsUserArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSupportCalculatorArgs = {
  input: SupportCalculatorInput;
};


export type MutationSyncTenderStatusesArgs = {
  token: Scalars['String']['input'];
};


export type MutationUpdateCompanyBillingDataArgs = {
  input: UpdateCompanyBillingDataInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpgradeProductCategorySubscriptionArgs = {
  input: UpgradeProductCategorySubscriptionInput;
};


export type MutationUpgradeToBespokeArgs = {
  token: Scalars['String']['input'];
};

export enum MyBidsFilterValues {
  OnlyTendersWithoutMyBids = 'ONLY_TENDERS_WITHOUT_MY_BIDS',
  OnlyTendersWithMyBids = 'ONLY_TENDERS_WITH_MY_BIDS'
}

export type MyBidsOption = {
  brandId: Scalars['Int']['input'];
  value: MyBidsFilterValues;
};

export enum MyTendersFilterValues {
  OnlyMyTenders = 'ONLY_MY_TENDERS',
  OnlyWithoutMyTenders = 'ONLY_WITHOUT_MY_TENDERS'
}

export type MyTendersOption = {
  value: MyTendersFilterValues;
  venueId: Scalars['Int']['input'];
};

export type Node = {
  id: Scalars['Int']['output'];
};

export type NonSubscribedProductCategory = {
  __typename?: 'NonSubscribedProductCategory';
  categoryName: Scalars['String']['output'];
  numberOfProductsInCategory: Scalars['Int']['output'];
  productCategoryId: Scalars['Int']['output'];
  stripeProductId: Scalars['String']['output'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  bid?: Maybe<Bid>;
  bidId?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['Int']['output']>;
  category: NotificationCategory;
  createdAtUtc: Scalars['Date']['output'];
  fromId?: Maybe<Scalars['Int']['output']>;
  fromType?: Maybe<NotificationTargetType>;
  id: Scalars['Int']['output'];
  isRead: Scalars['Boolean']['output'];
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['Int']['output']>;
  tender?: Maybe<Tender>;
  tenderId?: Maybe<Scalars['Int']['output']>;
  toId: Scalars['Int']['output'];
  toType: NotificationTargetType;
  type: NotificationType;
  updatedAtUtc?: Maybe<Scalars['Date']['output']>;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['Int']['output']>;
};

export enum NotificationCategory {
  Message = 'MESSAGE',
  System = 'SYSTEM'
}

export type NotificationEdge = Edge & {
  __typename?: 'NotificationEdge';
  cursor: Scalars['String']['output'];
  node: Notification;
};

export enum NotificationTargetType {
  Brand = 'BRAND',
  Venue = 'VENUE'
}

export enum NotificationType {
  BidRated = 'BID_RATED',
  BidUpdated = 'BID_UPDATED',
  GeneralMessage = 'GENERAL_MESSAGE',
  NewBid = 'NEW_BID',
  NewTender = 'NEW_TENDER',
  PaymentIntentFailed = 'PAYMENT_INTENT_FAILED',
  PaymentIntentFailedStandardTier = 'PAYMENT_INTENT_FAILED_STANDARD_TIER',
  ReTenderReminder = 'RE_TENDER_REMINDER',
  SubscriptionDeleted = 'SUBSCRIPTION_DELETED',
  SubscriptionDeletedStandardTier = 'SUBSCRIPTION_DELETED_STANDARD_TIER',
  TenderCloseWithoutWinner = 'TENDER_CLOSE_WITHOUT_WINNER',
  TenderClosing = 'TENDER_CLOSING',
  TenderEnded = 'TENDER_ENDED',
  TenderEndedReminder = 'TENDER_ENDED_REMINDER',
  TenderUpdated = 'TENDER_UPDATED',
  WinnerAnnouncement = 'WINNER_ANNOUNCEMENT'
}

export type OffsetNotifications = {
  __typename?: 'OffsetNotifications';
  data: Array<Notification>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
  unreadTotalCount: Scalars['Int']['output'];
};

export type OffsetNotificationsInput = {
  category: NotificationCategory;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  toId: Scalars['Int']['input'];
  toType: NotificationTargetType;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  totalPages: Scalars['Int']['output'];
};

export type OffsetPaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PlaceAutocomplete = {
  __typename?: 'PlaceAutocomplete';
  placeId: Scalars['String']['output'];
  placeName: Scalars['String']['output'];
};

export type PlacesInput = {
  region: Region;
  searchQuery: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type PointInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type PriceRangeOption = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  productCategorySubscriptions?: Maybe<Array<ProductCategorySubscription>>;
  regions: Array<Region>;
  unitType?: Maybe<Scalars['String']['output']>;
};

export type ProductCategorySubscription = {
  __typename?: 'ProductCategorySubscription';
  id: Scalars['Int']['output'];
  packageType: ProductCategorySubscriptionType;
  price: Scalars['Int']['output'];
  productCategoryId: Scalars['Int']['output'];
  stripeProductId: Scalars['String']['output'];
};

export enum ProductCategorySubscriptionType {
  Premium = 'PREMIUM',
  Standard = 'STANDARD'
}

export type ProductTemplate = {
  __typename?: 'ProductTemplate';
  avatarBucketKey?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  averageWholesalePrice: Scalars['Float']['output'];
  brand: Brand;
  brandId: Scalars['Int']['output'];
  files?: Maybe<Array<File>>;
  id: Scalars['Int']['output'];
  isAnyWholesalerCompatible: Scalars['Boolean']['output'];
  isDirectSupplyCompatible: Scalars['Boolean']['output'];
  packagingType: Scalars['String']['output'];
  productCategory: ProductCategory;
  productCategoryId: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  productUrl?: Maybe<Scalars['String']['output']>;
  unitType: Scalars['String']['output'];
  volumeUnits: Scalars['Int']['output'];
  wholesalers: Array<Wholesaler>;
};

export type ProductTemplateByIdInput = {
  brandId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type ProductTemplatesByBrandIdInput = {
  brandId: Scalars['Int']['input'];
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoDeal = {
  __typename?: 'PromoDeal';
  avatarBucketKey?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  averageWholesalePrice: Scalars['Float']['output'];
  brand?: Maybe<Brand>;
  brandId: Scalars['Int']['output'];
  createdAtUtc: Scalars['Date']['output'];
  files?: Maybe<Array<File>>;
  freeStockNotes?: Maybe<Scalars['String']['output']>;
  freeStockUnitsAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isAnyWholesalerCompatible: Scalars['Boolean']['output'];
  isDirectSupplyCompatible: Scalars['Boolean']['output'];
  listingFeeNotes?: Maybe<Scalars['String']['output']>;
  listingFeeTotalAmount?: Maybe<Scalars['Float']['output']>;
  otherSupport?: Maybe<Scalars['String']['output']>;
  packagingType: Scalars['String']['output'];
  posBudgetNotes?: Maybe<Scalars['String']['output']>;
  posBudgetTotalAmount?: Maybe<Scalars['Float']['output']>;
  productCategory?: Maybe<ProductCategory>;
  productCategoryId: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  productUrl?: Maybe<Scalars['String']['output']>;
  region: Region;
  retroDiscountAmountPerUnit?: Maybe<Scalars['Float']['output']>;
  retroDiscountNotes?: Maybe<Scalars['String']['output']>;
  status: PromoDealStatus;
  supportsFreeStock?: Maybe<Scalars['Boolean']['output']>;
  supportsListingFee?: Maybe<Scalars['Boolean']['output']>;
  supportsPosBudget?: Maybe<Scalars['Boolean']['output']>;
  supportsRetroDiscount?: Maybe<Scalars['Boolean']['output']>;
  unitType: Scalars['String']['output'];
  updatedAtUtc?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  volumeUnits: Scalars['Int']['output'];
  wholesalers: Array<Wholesaler>;
};

export type PromoDealProductOptions = {
  __typename?: 'PromoDealProductOptions';
  productCategories: Array<ProductCategory>;
  productPackaging: Array<Scalars['String']['output']>;
  productUnits: Array<Scalars['String']['output']>;
  wholesalers: Array<Wholesaler>;
};

export enum PromoDealStatus {
  Disabled = 'DISABLED',
  Published = 'PUBLISHED'
}

export type PromoDeals = {
  __typename?: 'PromoDeals';
  data: Array<PromoDeal>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PromoDealsFilterOptionsInput = {
  region?: InputMaybe<Region>;
  status?: InputMaybe<PromoDealStatus>;
};

export type PromoDealsInput = {
  filter?: InputMaybe<PromoDealsFilterOptionsInput>;
  pagination: OffsetPaginationInput;
};

export type Query = {
  __typename?: 'Query';
  addressByPlaceId: GoogleAddress;
  avatarUrl: Scalars['String']['output'];
  bankAccount?: Maybe<BankAccount>;
  bespokeTokenCheck: BespokeCheckData;
  bidById: Bid;
  bids: Bids;
  bidsViewFilterOptions: Array<Filter>;
  brandById: Brand;
  brandDashboardMetricsByBrandId: Array<BrandDashboardMetric>;
  brandSettingsByBrandId: BrandSettings;
  canCreateBid: Scalars['Boolean']['output'];
  cursorNotifications: CursorNotifications;
  getAwrsDetails: AwrsDetails;
  getCompanyVatDetails: CompanyVatDetails;
  getDataFromCompanyNumber: RegisteredCompanyWithoutId;
  getLiveStats: LiveStats;
  hasActiveSubscription: Scalars['Boolean']['output'];
  hello: Scalars['String']['output'];
  invoices: Array<Invoice>;
  listingTypes: Array<ListingType>;
  locationByCoordinates: GoogleLocationByCoordinates;
  messagesByBidId: Array<Message>;
  nonSubscribedProductCategories: Array<NonSubscribedProductCategory>;
  offsetNotifications: OffsetNotifications;
  places: Array<PlaceAutocomplete>;
  previousBid?: Maybe<Bid>;
  productCategories: Array<ProductCategory>;
  productCategorySubscriptions: Array<ProductCategorySubscription>;
  productPackaging: Array<Scalars['String']['output']>;
  productTemplateById: ProductTemplate;
  productTemplatesByBrandId: Array<ProductTemplate>;
  productUnits: Array<Scalars['String']['output']>;
  promoDealProductOptions: PromoDealProductOptions;
  promoDeals: PromoDeals;
  ratingFeedbackValues: Array<RatingFeedbackValue>;
  ratingOptions: Array<RatingOption>;
  retoolBidsForTenderAndBidStatusChange: Array<RetoolBidsForStatusChange>;
  retoolBrandById: Brand;
  retoolBrandInList: Brands;
  retoolUsersInList: Users;
  retoolVenuesForTenderAndBidStatusChange: Array<RetoolVenuesForStatusChange>;
  searchVenueGroups: Venues;
  searchVenues: Array<Venue>;
  searchWholesalers: Wholesalers;
  subscription: Subscription;
  subscriptions: Array<StripeProductCategorySubscription>;
  successFeeInvoiceById: SuccessFeeInvoice;
  successFeeInvoiceList: Array<SuccessFeeInvoiceListItem>;
  tenderById: Tender;
  tenderDraftById: TenderDraft;
  tenderList: Tenders;
  tenderListFilterOptions: Array<Filter>;
  tenderPreferenceByVenueId: TenderPreference;
  topTendersBidsCountMinBoundary: Scalars['Int']['output'];
  totalSupportFromBrandPerActiveVenue: TotalSupportFromBrandPerVenue;
  userExists: Scalars['Boolean']['output'];
  userMe: User;
  userVenues: Venues;
  userVenuesByName: Array<Venue>;
  venueById: Venue;
  venueCategories: Array<VenueCategory>;
  venueDashboardMetricsByVenueId: Array<VenueDashboardMetric>;
  venueDashboardTenders: UnionTenders;
  venueSettingsByVenueId: VenueSettings;
  winningBidsByTenderId: Array<Bid>;
};


export type QueryAddressByPlaceIdArgs = {
  input: AddressByPlaceIdInput;
};


export type QueryAvatarUrlArgs = {
  input: AvatarUrlInput;
};


export type QueryBespokeTokenCheckArgs = {
  token: Scalars['String']['input'];
};


export type QueryBidByIdArgs = {
  bidId: Scalars['Int']['input'];
};


export type QueryBidsArgs = {
  input: BidsInput;
};


export type QueryBidsViewFilterOptionsArgs = {
  input: BidsFilterOptionsInput;
};


export type QueryBrandByIdArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryBrandDashboardMetricsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryBrandSettingsByBrandIdArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryCursorNotificationsArgs = {
  input: CursorNotificationsInput;
};


export type QueryGetAwrsDetailsArgs = {
  input: GetAwrsDetailsInput;
};


export type QueryGetCompanyVatDetailsArgs = {
  input: GetCompanyVatDetailsInput;
};


export type QueryGetDataFromCompanyNumberArgs = {
  input: GetDataFromCompanyNumberInput;
};


export type QueryHelloArgs = {
  input: HelloInput;
};


export type QueryInvoicesArgs = {
  stripeCustomerId: Scalars['String']['input'];
};


export type QueryLocationByCoordinatesArgs = {
  input: LocationByCoordinatesInput;
};


export type QueryMessagesByBidIdArgs = {
  bidId: Scalars['Int']['input'];
};


export type QueryNonSubscribedProductCategoriesArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
};


export type QueryOffsetNotificationsArgs = {
  input: OffsetNotificationsInput;
};


export type QueryPlacesArgs = {
  input: PlacesInput;
};


export type QueryPreviousBidArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryProductTemplateByIdArgs = {
  input: ProductTemplateByIdInput;
};


export type QueryProductTemplatesByBrandIdArgs = {
  input: ProductTemplatesByBrandIdInput;
};


export type QueryPromoDealProductOptionsArgs = {
  region: Region;
};


export type QueryPromoDealsArgs = {
  input: PromoDealsInput;
};


export type QueryRetoolBidsForTenderAndBidStatusChangeArgs = {
  tenderId: Scalars['Int']['input'];
};


export type QueryRetoolBrandByIdArgs = {
  brandId: Scalars['Int']['input'];
};


export type QueryRetoolBrandInListArgs = {
  input: RetoolBrandListInput;
};


export type QueryRetoolUsersInListArgs = {
  input: RetoolUsersInListInput;
};


export type QuerySearchVenueGroupsArgs = {
  input: SearchVenueGroupsInput;
};


export type QuerySearchVenuesArgs = {
  input: SearchVenuesInput;
};


export type QuerySearchWholesalersArgs = {
  input: WholesalerSearchInput;
};


export type QuerySubscriptionArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
};


export type QuerySubscriptionsArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
};


export type QuerySuccessFeeInvoiceByIdArgs = {
  invoiceId: Scalars['String']['input'];
};


export type QuerySuccessFeeInvoiceListArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryTenderByIdArgs = {
  tenderId: Scalars['Int']['input'];
};


export type QueryTenderDraftByIdArgs = {
  tenderDraftId: Scalars['Int']['input'];
};


export type QueryTenderListArgs = {
  input: TendersInput;
};


export type QueryTenderPreferenceByVenueIdArgs = {
  venueId: Scalars['Int']['input'];
};


export type QueryTotalSupportFromBrandPerActiveVenueArgs = {
  brandId: Scalars['Int']['input'];
  venueId: Scalars['Int']['input'];
};


export type QueryUserExistsArgs = {
  input: UserExistsInput;
};


export type QueryUserMeArgs = {
  input?: InputMaybe<UserMeInput>;
};


export type QueryUserVenuesArgs = {
  input: UserVenuesInput;
};


export type QueryUserVenuesByNameArgs = {
  venueName: Scalars['String']['input'];
};


export type QueryVenueByIdArgs = {
  venueId: Scalars['Int']['input'];
};


export type QueryVenueCategoriesArgs = {
  region?: InputMaybe<Region>;
};


export type QueryVenueDashboardMetricsByVenueIdArgs = {
  venueId: Scalars['Int']['input'];
};


export type QueryVenueDashboardTendersArgs = {
  input: VenueDashboardTendersInput;
};


export type QueryVenueSettingsByVenueIdArgs = {
  venueId: Scalars['Int']['input'];
};


export type QueryWinningBidsByTenderIdArgs = {
  tenderId: Scalars['Int']['input'];
};

export type RateBidInput = {
  bidId: Scalars['Int']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
  ratingComment?: InputMaybe<Scalars['String']['input']>;
  ratingOptions: Array<Scalars['Int']['input']>;
};

export type RatingFeedbackValue = {
  __typename?: 'RatingFeedbackValue';
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RatingOption = {
  __typename?: 'RatingOption';
  name: Scalars['String']['output'];
  type: RatingOptionType;
};

export enum RatingOptionType {
  All = 'ALL',
  Or = 'OR'
}

/** Regions */
export enum Region {
  Australia = 'Australia',
  Spain = 'Spain',
  UnitedKingdom = 'UnitedKingdom'
}

export type RegisteredCompany = {
  __typename?: 'RegisteredCompany';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dateOfCreation?: Maybe<Scalars['String']['output']>;
  firstAddressLine?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  secondAddressLine?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type RegisteredCompanyInput = {
  address?: InputMaybe<GoogleAddressInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  dateOfCreation?: InputMaybe<Scalars['String']['input']>;
  firstAddressLine?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  number: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  secondAddressLine?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type RegisteredCompanyWithoutId = {
  __typename?: 'RegisteredCompanyWithoutId';
  country?: Maybe<Scalars['String']['output']>;
  dateOfCreation: Scalars['String']['output'];
  firstAddressLine?: Maybe<Scalars['String']['output']>;
  formattedAddress: Scalars['String']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  secondAddressLine?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type RemoveProductCategorySubscriptionInput = {
  brandId: Scalars['Int']['input'];
  packageType: ProductCategorySubscriptionType;
  productCategoryId: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type RequestEmailArgument = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type RequestEmailInput = {
  emailType: EmailType;
  region: Region;
  requestEmailArguments: Array<RequestEmailArgument>;
  requesterEmail: Scalars['String']['input'];
};

export type RetoolBrandListFilter = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Region>;
};

export type RetoolBrandListInput = {
  filter?: InputMaybe<RetoolBrandListFilter>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderDirection?: InputMaybe<SortDirectionEnum>;
};

export type RetoolChangeBidStatusInput = {
  bidId: Scalars['Int']['input'];
  bidStatus: BidStatus;
};

export type RetoolChangeTenderAllBidsStatusInput = {
  bidStatus: BidStatus;
  tenderId: Scalars['Int']['input'];
};

export type RetoolChangeTenderStatusInput = {
  tenderId: Scalars['Int']['input'];
  tenderStatus: TenderStatus;
};

export type RetoolUsersFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Region>;
};

export type RetoolUsersInListInput = {
  filter?: InputMaybe<RetoolUsersFilterInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderDirection?: InputMaybe<SortDirectionEnum>;
};

export type SampleRequestInput = {
  bidId: Scalars['Int']['input'];
  googlePlacesSessionToken: Scalars['String']['input'];
  sampleDeliveryPlaceId: Scalars['String']['input'];
  sampleMessage: Scalars['String']['input'];
  venueId: Scalars['Int']['input'];
};

export type SearchVenueGroupsInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  publicTendersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  searchQuery: Scalars['String']['input'];
  sortDirection?: InputMaybe<SortDirectionEnum>;
};

export type SearchVenuesInput = {
  venueName: Scalars['String']['input'];
  withPublicTendersOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SignUpInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  region: Region;
  registeredCompany?: InputMaybe<RegisteredCompanyInput>;
  userType: UserType;
};

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StripeProductCategorySubscription = {
  __typename?: 'StripeProductCategorySubscription';
  categoryName: Scalars['String']['output'];
  numberOfProductsInCategory: Scalars['Int']['output'];
  packageType: ProductCategorySubscriptionType;
  pricePerMonth: Scalars['Int']['output'];
  productCategoryId: Scalars['Int']['output'];
  productCategorySubscriptionId: Scalars['Int']['output'];
  stripeProductId: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAtUtc: Scalars['Int']['output'];
  currentPeriodEnd: Scalars['Int']['output'];
  currentPeriodStart: Scalars['Int']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isCanceled: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  subscriptionTier?: Maybe<SubscriptionTier>;
  successFee?: Maybe<Scalars['Float']['output']>;
};

export enum SubscriptionTier {
  Enterprise = 'ENTERPRISE',
  Premium = 'PREMIUM',
  Standard = 'STANDARD'
}

export type SuccessFeeInvoice = {
  __typename?: 'SuccessFeeInvoice';
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paymentUrl: Scalars['String']['output'];
  pdfUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type SuccessFeeInvoiceListItem = {
  __typename?: 'SuccessFeeInvoiceListItem';
  averageWholesaleBidPrice: Scalars['Float']['output'];
  bidUnitType: Scalars['String']['output'];
  bidVolumeUnits: Scalars['Float']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['String']['output'];
  estimatedVolume: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  packagingType: Scalars['String']['output'];
  paymentUrl: Scalars['String']['output'];
  pdfUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
  successFee: Scalars['Float']['output'];
  tenderName: Scalars['String']['output'];
  tenderUnitType: Scalars['String']['output'];
  tenderVolumeUnits: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  venueName: Scalars['String']['output'];
};

export type SupportCalculatorInput = {
  email: Scalars['String']['input'];
  numberOfVenues: Scalars['Int']['input'];
  region: Region;
  totalBeveragePurchases: Scalars['Int']['input'];
  venueType: Scalars['String']['input'];
};

export type Tender = {
  __typename?: 'Tender';
  createdAtUtc?: Maybe<Scalars['String']['output']>;
  estimatedVolume?: Maybe<Scalars['Int']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<File>>;
  hasBrandsBid?: Maybe<Scalars['Boolean']['output']>;
  hostingChargeInvoiceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAnyWholesalerCompatible?: Maybe<Scalars['Boolean']['output']>;
  isDirectSupplyCompatible?: Maybe<Scalars['Boolean']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  listingEndsAtUtc?: Maybe<Scalars['Date']['output']>;
  listingStartsAtUtc?: Maybe<Scalars['Date']['output']>;
  listingType: ListingType;
  listingTypeId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  packagingType?: Maybe<Scalars['String']['output']>;
  productCategory: ProductCategory;
  productCategoryId: Scalars['Int']['output'];
  receivedSupportStatus?: Maybe<TenderSupportStatus>;
  region: Region;
  status: TenderStatus;
  supportDetails?: Maybe<Scalars['String']['output']>;
  supportsFreeStock?: Maybe<TenderSupportOption>;
  supportsListingFee?: Maybe<TenderSupportOption>;
  supportsPosBudget?: Maybe<TenderSupportOption>;
  supportsRetroDiscount?: Maybe<TenderSupportOption>;
  tenderDetails?: Maybe<Scalars['String']['output']>;
  tenderEndsAtUtc?: Maybe<Scalars['Date']['output']>;
  tenderStartsAtUtc?: Maybe<Scalars['Date']['output']>;
  totalReceivedBids: Scalars['Int']['output'];
  unitType?: Maybe<Scalars['String']['output']>;
  updatedAtUtc?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  venue: Venue;
  venueId: Scalars['Int']['output'];
  volumeDetails?: Maybe<Scalars['String']['output']>;
  volumeUnits?: Maybe<Scalars['Int']['output']>;
  wholesalers?: Maybe<Array<Wholesaler>>;
};

export type TenderDraft = {
  __typename?: 'TenderDraft';
  createdAtUtc: Scalars['String']['output'];
  estimatedVolume?: Maybe<Scalars['Int']['output']>;
  files: Array<File>;
  id: Scalars['Int']['output'];
  isAnyWholesalerCompatible: Scalars['Boolean']['output'];
  isDirectSupplyCompatible?: Maybe<Scalars['Boolean']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  listingEndsAtUtc?: Maybe<Scalars['Date']['output']>;
  listingStartsAtUtc?: Maybe<Scalars['Date']['output']>;
  listingType: ListingType;
  listingTypeId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  packagingType?: Maybe<Scalars['String']['output']>;
  productCategory: ProductCategory;
  productCategoryId: Scalars['Int']['output'];
  region: Region;
  supportDetails?: Maybe<Scalars['String']['output']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  tenderDetails?: Maybe<Scalars['String']['output']>;
  tenderEndsAtUtc?: Maybe<Scalars['Date']['output']>;
  tenderStartsAtUtc?: Maybe<Scalars['Date']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
  updatedAtUtc?: Maybe<Scalars['String']['output']>;
  user: User;
  userId: Scalars['Int']['output'];
  venue: Venue;
  venueId: Scalars['Int']['output'];
  volumeDetails?: Maybe<Scalars['String']['output']>;
  volumeUnits?: Maybe<Scalars['Int']['output']>;
  wholesalers: Array<Wholesaler>;
};

export type TenderFilterOptionsInput = {
  administrativeAreaLevel2?: InputMaybe<Array<Scalars['String']['input']>>;
  listingTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  myBids?: InputMaybe<MyBidsOption>;
  myTenders?: InputMaybe<MyTendersOption>;
  postCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  productCategoryId?: InputMaybe<Scalars['Int']['input']>;
  venueGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  venueIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  venueTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  volume?: InputMaybe<VolumeRangeOption>;
  wholesalerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type TenderListFilterOption = {
  __typename?: 'TenderListFilterOption';
  label: Scalars['String']['output'];
  options: Array<FilterOption>;
};

export enum TenderListOrderByEnum {
  Duration = 'DURATION',
  RecentlyAdded = 'RECENTLY_ADDED',
  TimeLeft = 'TIME_LEFT',
  Volume = 'VOLUME'
}

export type TenderListPaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<TenderListOrderByEnum>;
  orderDirection?: InputMaybe<SortDirectionEnum>;
};

export type TenderPreference = {
  __typename?: 'TenderPreference';
  id: Scalars['Int']['output'];
  isAnyWholesalerCompatible?: Maybe<Scalars['Boolean']['output']>;
  isDirectSupplyCompatible?: Maybe<Scalars['Boolean']['output']>;
  supportDetails?: Maybe<Scalars['String']['output']>;
  supportsFreeStock: TenderSupportOption;
  supportsListingFee: TenderSupportOption;
  supportsPosBudget: TenderSupportOption;
  supportsRetroDiscount: TenderSupportOption;
  venueId: Scalars['Int']['output'];
  wholesalers: Array<Wholesaler>;
};

export type TenderSortInput = {
  orderBy?: InputMaybe<VenueDashboardOrderByEnum>;
  orderDirection?: InputMaybe<SortDirectionEnum>;
};

export enum TenderStatus {
  Awarded = 'AWARDED',
  Closed = 'CLOSED',
  ClosedWithoutWinner = 'CLOSED_WITHOUT_WINNER',
  Deleted = 'DELETED',
  Published = 'PUBLISHED'
}

export enum TenderSupportOption {
  NotInterested = 'NOT_INTERESTED',
  Optional = 'OPTIONAL',
  Preferred = 'PREFERRED'
}

export enum TenderSupportStatus {
  InProgress = 'IN_PROGRESS',
  NotProvided = 'NOT_PROVIDED',
  Received = 'RECEIVED'
}

export type Tenders = {
  __typename?: 'Tenders';
  data: Array<Tender>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TendersInput = {
  excludedTenderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  filter?: InputMaybe<TenderFilterOptionsInput>;
  pagination?: InputMaybe<TenderListPaginationInput>;
};

export type TotalSupportFromBrandPerVenue = {
  __typename?: 'TotalSupportFromBrandPerVenue';
  totalSupport: Scalars['Float']['output'];
  totalTenders: Scalars['Int']['output'];
};

export type UnionTenderInList = Tender | TenderDraft;

export type UnionTenders = {
  __typename?: 'UnionTenders';
  data: Array<UnionTenderInList>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UpdateCompanyBillingDataInput = {
  activeVenueId?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<GoogleAddressInput>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  googlePlacesSessionToken: Scalars['String']['input'];
  registeredCompany?: InputMaybe<RegisteredCompanyInput>;
  tradingAddress?: InputMaybe<GoogleAddressInput>;
  tradingName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubscriptionInput = {
  stripeCustomerId: Scalars['String']['input'];
  stripeSubscriptionId: Scalars['String']['input'];
  subscriptionTier: SubscriptionTier;
  successFee: Scalars['Float']['input'];
};

export type UpgradeProductCategorySubscriptionInput = {
  brandId: Scalars['Int']['input'];
  productCategoryId: Scalars['Int']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type UpgradeToBespokeData = {
  __typename?: 'UpgradeToBespokeData';
  needsRedirect: Scalars['Boolean']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  wasSuccessfullyUpdated: Scalars['Boolean']['output'];
};

export type UploadUrlItem = {
  __typename?: 'UploadUrlItem';
  bucketKey: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  activeVenue?: Maybe<Venue>;
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']['output']>;
  brands: Array<Brand>;
  companyName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firebaseId: Scalars['String']['output'];
  firstFourVenues: Array<Venue>;
  id: Scalars['Int']['output'];
  isSalesDemo?: Maybe<Scalars['Boolean']['output']>;
  region: Region;
  registeredCompany?: Maybe<RegisteredCompany>;
  tradingAddress?: Maybe<Address>;
  tradingName?: Maybe<Scalars['String']['output']>;
  userType: UserType;
  venues: Array<Venue>;
};

export type UserActionLog = {
  __typename?: 'UserActionLog';
  additionalData?: Maybe<Scalars['Object']['output']>;
  eventName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
};

export type UserActionLogInput = {
  additionalData?: InputMaybe<Scalars['Object']['input']>;
  eventName: Scalars['String']['input'];
};

export type UserExistsInput = {
  email: Scalars['String']['input'];
};

export type UserMeInput = {
  activeVenueId?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserType {
  Brand = 'BRAND',
  Venue = 'VENUE'
}

export type UserVenuesInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserVenuesInputFilter>;
  first: Scalars['Int']['input'];
  sortColumn?: InputMaybe<UserVenuesSortColumnEnum>;
  sortDirection?: InputMaybe<SortDirectionEnum>;
};

export type UserVenuesInputFilter = {
  venueName?: InputMaybe<Scalars['String']['input']>;
};

export enum UserVenuesSortColumnEnum {
  Name = 'name'
}

export type Users = {
  __typename?: 'Users';
  data: Array<User>;
  pageInfo: OffsetPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Venue = Node & {
  __typename?: 'Venue';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarBucketKey?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  canCreateTender: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  hasTenders: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  region: Region;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  tenderPreference: TenderPreference;
  user?: Maybe<User>;
  userId: Scalars['Int']['output'];
  venueCategory?: Maybe<VenueCategory>;
  venueCategoryId?: Maybe<Scalars['Int']['output']>;
  venueCategoryName?: Maybe<Scalars['String']['output']>;
  webPageUrl?: Maybe<Scalars['String']['output']>;
};

export type VenueCategory = {
  __typename?: 'VenueCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  venues: Array<Venue>;
};

export type VenueDashboardFilterOptionsInput = {
  status: VenueDashboardTenderStatus;
};

export type VenueDashboardMetric = {
  __typename?: 'VenueDashboardMetric';
  label: VenueDashboardMetricLabel;
  type: DashboardMetricType;
  value: Scalars['String']['output'];
};

export enum VenueDashboardMetricLabel {
  ActualTenderVolume = 'ACTUAL_TENDER_VOLUME',
  AverageBidsCount = 'AVERAGE_BIDS_COUNT',
  SupportPerBottle = 'SUPPORT_PER_BOTTLE',
  TotalSupportValue = 'TOTAL_SUPPORT_VALUE'
}

export enum VenueDashboardOrderByEnum {
  RecentlyAdded = 'RECENTLY_ADDED',
  SupportReceived = 'SUPPORT_RECEIVED',
  TimeLeft = 'TIME_LEFT',
  Volume = 'VOLUME'
}

export enum VenueDashboardTenderStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Past = 'PAST'
}

export type VenueDashboardTendersInput = {
  filter: VenueDashboardFilterOptionsInput;
  pagination: OffsetPaginationInput;
  sort?: InputMaybe<TenderSortInput>;
  venueId: Scalars['Int']['input'];
};

export enum VenueDashboardViews {
  BidView = 'BID_VIEW',
  TenderView = 'TENDER_VIEW'
}

export type VenueEdge = Edge & {
  __typename?: 'VenueEdge';
  cursor: Scalars['String']['output'];
  node: Venue;
};

export type VenueSettings = {
  __typename?: 'VenueSettings';
  id: Scalars['Int']['output'];
  isBidUpdatedInAppNotificationEnabled: Scalars['Boolean']['output'];
  isBidUpdatedMailNotificationEnabled: Scalars['Boolean']['output'];
  isMyTenderEndedInAppNotificationEnabled: Scalars['Boolean']['output'];
  isMyTenderEndedMailNotificationEnabled: Scalars['Boolean']['output'];
  isNewBidsInAppNotificationEnabled: Scalars['Boolean']['output'];
  isNewBidsMailNotificationEnabled: Scalars['Boolean']['output'];
  isNewTenderInAppNotificationEnabled: Scalars['Boolean']['output'];
  isNewTenderMailNotificationEnabled: Scalars['Boolean']['output'];
  isReTenderInAppNotificationEnabled: Scalars['Boolean']['output'];
  isReTenderMailNotificationEnabled: Scalars['Boolean']['output'];
  venueId: Scalars['Int']['output'];
};

export type Venues = CursorPagination & {
  __typename?: 'Venues';
  edges: Array<VenueEdge>;
  pageInfo: CursorPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type VolumeRangeOption = {
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type Wholesaler = Node & {
  __typename?: 'Wholesaler';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  region: Region;
};

export type WholesalerEdge = Edge & {
  __typename?: 'WholesalerEdge';
  cursor: Scalars['String']['output'];
  node: Wholesaler;
};

export type WholesalerSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  searchQuery: Scalars['String']['input'];
  sortDirection?: InputMaybe<SortDirectionEnum>;
};

export type Wholesalers = CursorPagination & {
  __typename?: 'Wholesalers';
  edges: Array<WholesalerEdge>;
  pageInfo: CursorPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RetoolBidsForStatusChange = {
  __typename?: 'retoolBidsForStatusChange';
  bidId: Scalars['Int']['output'];
  bidStatus: BidStatus;
  brandName: Scalars['String']['output'];
  brandUserId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  tenderId: Scalars['Int']['output'];
  tenderName: Scalars['String']['output'];
  tenderStatus: TenderStatus;
  venueName: Scalars['String']['output'];
  venueUserId: Scalars['Int']['output'];
};

export type RetoolVenuesForStatusChange = {
  __typename?: 'retoolVenuesForStatusChange';
  tenderId: Scalars['Int']['output'];
  tenderName: Scalars['String']['output'];
  tenderStatus: TenderStatus;
  venueName: Scalars['String']['output'];
  venueUserId: Scalars['Int']['output'];
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AddBankAccountInput: AddBankAccountInput;
  AddBidComparisonAnalyticInput: AddBidComparisonAnalyticInput;
  AddBidInput: AddBidInput;
  AddBidsToMultipleTendersInput: AddBidsToMultipleTendersInput;
  AddMessageInput: AddMessageInput;
  AddMultipleBidInput: AddMultipleBidInput;
  AddProductCategorySubscriptionInput: AddProductCategorySubscriptionInput;
  AddProductTemplateInput: AddProductTemplateInput;
  AddPromoDealInput: AddPromoDealInput;
  AddTenderDraftInput: AddTenderDraftInput;
  AddTenderInput: AddTenderInput;
  Address: ResolverTypeWrapper<Address>;
  AddressByPlaceIdInput: AddressByPlaceIdInput;
  AvatarUrlInput: AvatarUrlInput;
  AwrsDetails: ResolverTypeWrapper<AwrsDetails>;
  BankAccount: ResolverTypeWrapper<BankAccount>;
  BespokeCheckData: ResolverTypeWrapper<BespokeCheckData>;
  Bid: ResolverTypeWrapper<Bid>;
  BidRatingFeedbackValue: ResolverTypeWrapper<BidRatingFeedbackValue>;
  BidStatus: BidStatus;
  BidSupportType: BidSupportType;
  Bids: ResolverTypeWrapper<Bids>;
  BidsFilterOptionsInput: BidsFilterOptionsInput;
  BidsInput: BidsInput;
  BidsOrderByEnum: BidsOrderByEnum;
  BidsSortInput: BidsSortInput;
  BidsViewFiltersInput: BidsViewFiltersInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Brand: ResolverTypeWrapper<Brand>;
  BrandDashboardBidStatus: BrandDashboardBidStatus;
  BrandDashboardFilterOptionsInput: BrandDashboardFilterOptionsInput;
  BrandDashboardMetric: ResolverTypeWrapper<BrandDashboardMetric>;
  BrandDashboardMetricLabel: BrandDashboardMetricLabel;
  BrandDashboardWonBidsSortInput: BrandDashboardWonBidsSortInput;
  BrandSettings: ResolverTypeWrapper<BrandSettings>;
  Brands: ResolverTypeWrapper<Brands>;
  CancelSubscriptionInput: CancelSubscriptionInput;
  CancelSubscriptionNewInAppInput: CancelSubscriptionNewInAppInput;
  CancelSubscriptionNewRetoolInput: CancelSubscriptionNewRetoolInput;
  ChangeEmailInput: ChangeEmailInput;
  ChooseMultipleTenderWinnersInput: ChooseMultipleTenderWinnersInput;
  ChooseWinnerInput: ChooseWinnerInput;
  CompanyVatDetails: ResolverTypeWrapper<CompanyVatDetails>;
  Coordinates: ResolverTypeWrapper<Coordinates>;
  CoordinatesInput: CoordinatesInput;
  CreateBankTransferSubscriptionInput: CreateBankTransferSubscriptionInput;
  CreateBespokePaymentLinkInput: CreateBespokePaymentLinkInput;
  CreateStripeCheckoutInput: CreateStripeCheckoutInput;
  CreateStripeCheckoutNewInput: CreateStripeCheckoutNewInput;
  CursorNotifications: ResolverTypeWrapper<CursorNotifications>;
  CursorNotificationsInput: CursorNotificationsInput;
  CursorPageInfo: ResolverTypeWrapper<CursorPageInfo>;
  CursorPagination: ResolversTypes['CursorNotifications'] | ResolversTypes['Venues'] | ResolversTypes['Wholesalers'];
  CursorPaginationInput: CursorPaginationInput;
  DashboardMetricType: DashboardMetricType;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DeleteBidInput: DeleteBidInput;
  DeleteProductTemplateInput: DeleteProductTemplateInput;
  Edge: ResolversTypes['NotificationEdge'] | ResolversTypes['VenueEdge'] | ResolversTypes['WholesalerEdge'];
  EditBankAccountInput: EditBankAccountInput;
  EditBidInput: EditBidInput;
  EditBidSupportStatusInput: EditBidSupportStatusInput;
  EditBrandInput: EditBrandInput;
  EditBrandSettingsInput: EditBrandSettingsInput;
  EditProductTemplateInput: EditProductTemplateInput;
  EditPromoDealInput: EditPromoDealInput;
  EditTenderDraftInput: EditTenderDraftInput;
  EditTenderInput: EditTenderInput;
  EditTenderPreferenceInput: EditTenderPreferenceInput;
  EditVenueInput: EditVenueInput;
  EditVenueSettingsInput: EditVenueSettingsInput;
  EmailType: EmailType;
  Feature: Feature;
  File: ResolverTypeWrapper<File>;
  FileContext: FileContext;
  FileInput: FileInput;
  Filter: ResolverTypeWrapper<Filter>;
  FilterOption: ResolverTypeWrapper<FilterOption>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GetAwrsDetailsInput: GetAwrsDetailsInput;
  GetCompanyVatDetailsInput: GetCompanyVatDetailsInput;
  GetDataFromCompanyNumberInput: GetDataFromCompanyNumberInput;
  GoogleAddress: ResolverTypeWrapper<GoogleAddress>;
  GoogleAddressInput: GoogleAddressInput;
  GoogleLocationByCoordinates: ResolverTypeWrapper<GoogleLocationByCoordinates>;
  HelloInput: HelloInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Invoice: ResolverTypeWrapper<Invoice>;
  ListingType: ResolverTypeWrapper<ListingType>;
  LiveStats: ResolverTypeWrapper<LiveStats>;
  LocationByCoordinatesInput: LocationByCoordinatesInput;
  MarkAllNotificationsAsReadInput: MarkAllNotificationsAsReadInput;
  Message: ResolverTypeWrapper<Message>;
  MultipleTenderWinnersInput: MultipleTenderWinnersInput;
  Mutation: ResolverTypeWrapper<{}>;
  MyBidsFilterValues: MyBidsFilterValues;
  MyBidsOption: MyBidsOption;
  MyTendersFilterValues: MyTendersFilterValues;
  MyTendersOption: MyTendersOption;
  Node: ResolversTypes['Notification'] | ResolversTypes['Venue'] | ResolversTypes['Wholesaler'];
  NonSubscribedProductCategory: ResolverTypeWrapper<NonSubscribedProductCategory>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationCategory: NotificationCategory;
  NotificationEdge: ResolverTypeWrapper<NotificationEdge>;
  NotificationTargetType: NotificationTargetType;
  NotificationType: NotificationType;
  Object: ResolverTypeWrapper<Scalars['Object']>;
  OffsetNotifications: ResolverTypeWrapper<OffsetNotifications>;
  OffsetNotificationsInput: OffsetNotificationsInput;
  OffsetPageInfo: ResolverTypeWrapper<OffsetPageInfo>;
  OffsetPaginationInput: OffsetPaginationInput;
  PlaceAutocomplete: ResolverTypeWrapper<PlaceAutocomplete>;
  PlacesInput: PlacesInput;
  Point: ResolverTypeWrapper<Point>;
  PointInput: PointInput;
  PriceRangeOption: PriceRangeOption;
  ProductCategory: ResolverTypeWrapper<ProductCategory>;
  ProductCategorySubscription: ResolverTypeWrapper<ProductCategorySubscription>;
  ProductCategorySubscriptionType: ProductCategorySubscriptionType;
  ProductTemplate: ResolverTypeWrapper<ProductTemplate>;
  ProductTemplateByIdInput: ProductTemplateByIdInput;
  ProductTemplatesByBrandIdInput: ProductTemplatesByBrandIdInput;
  PromoDeal: ResolverTypeWrapper<PromoDeal>;
  PromoDealProductOptions: ResolverTypeWrapper<PromoDealProductOptions>;
  PromoDealStatus: PromoDealStatus;
  PromoDeals: ResolverTypeWrapper<PromoDeals>;
  PromoDealsFilterOptionsInput: PromoDealsFilterOptionsInput;
  PromoDealsInput: PromoDealsInput;
  Query: ResolverTypeWrapper<{}>;
  RateBidInput: RateBidInput;
  RatingFeedbackValue: ResolverTypeWrapper<RatingFeedbackValue>;
  RatingOption: ResolverTypeWrapper<RatingOption>;
  RatingOptionType: RatingOptionType;
  Region: Region;
  RegisteredCompany: ResolverTypeWrapper<RegisteredCompany>;
  RegisteredCompanyInput: RegisteredCompanyInput;
  RegisteredCompanyWithoutId: ResolverTypeWrapper<RegisteredCompanyWithoutId>;
  RemoveProductCategorySubscriptionInput: RemoveProductCategorySubscriptionInput;
  RequestEmailArgument: RequestEmailArgument;
  RequestEmailInput: RequestEmailInput;
  RetoolBrandListFilter: RetoolBrandListFilter;
  RetoolBrandListInput: RetoolBrandListInput;
  RetoolChangeBidStatusInput: RetoolChangeBidStatusInput;
  RetoolChangeTenderAllBidsStatusInput: RetoolChangeTenderAllBidsStatusInput;
  RetoolChangeTenderStatusInput: RetoolChangeTenderStatusInput;
  RetoolUsersFilterInput: RetoolUsersFilterInput;
  RetoolUsersInListInput: RetoolUsersInListInput;
  SampleRequestInput: SampleRequestInput;
  SearchVenueGroupsInput: SearchVenueGroupsInput;
  SearchVenuesInput: SearchVenuesInput;
  SignUpInput: SignUpInput;
  SortDirectionEnum: SortDirectionEnum;
  String: ResolverTypeWrapper<Scalars['String']>;
  StripeProductCategorySubscription: ResolverTypeWrapper<StripeProductCategorySubscription>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionTier: SubscriptionTier;
  SuccessFeeInvoice: ResolverTypeWrapper<SuccessFeeInvoice>;
  SuccessFeeInvoiceListItem: ResolverTypeWrapper<SuccessFeeInvoiceListItem>;
  SupportCalculatorInput: SupportCalculatorInput;
  Tender: ResolverTypeWrapper<Tender>;
  TenderDraft: ResolverTypeWrapper<TenderDraft>;
  TenderFilterOptionsInput: TenderFilterOptionsInput;
  TenderListFilterOption: ResolverTypeWrapper<TenderListFilterOption>;
  TenderListOrderByEnum: TenderListOrderByEnum;
  TenderListPaginationInput: TenderListPaginationInput;
  TenderPreference: ResolverTypeWrapper<TenderPreference>;
  TenderSortInput: TenderSortInput;
  TenderStatus: TenderStatus;
  TenderSupportOption: TenderSupportOption;
  TenderSupportStatus: TenderSupportStatus;
  Tenders: ResolverTypeWrapper<Tenders>;
  TendersInput: TendersInput;
  TotalSupportFromBrandPerVenue: ResolverTypeWrapper<TotalSupportFromBrandPerVenue>;
  UnionTenderInList: ResolversTypes['Tender'] | ResolversTypes['TenderDraft'];
  UnionTenders: ResolverTypeWrapper<Omit<UnionTenders, 'data'> & { data: Array<ResolversTypes['UnionTenderInList']> }>;
  UpdateCompanyBillingDataInput: UpdateCompanyBillingDataInput;
  UpdateSubscriptionInput: UpdateSubscriptionInput;
  UpgradeProductCategorySubscriptionInput: UpgradeProductCategorySubscriptionInput;
  UpgradeToBespokeData: ResolverTypeWrapper<UpgradeToBespokeData>;
  UploadUrlItem: ResolverTypeWrapper<UploadUrlItem>;
  User: ResolverTypeWrapper<User>;
  UserActionLog: ResolverTypeWrapper<UserActionLog>;
  UserActionLogInput: UserActionLogInput;
  UserExistsInput: UserExistsInput;
  UserMeInput: UserMeInput;
  UserType: UserType;
  UserVenuesInput: UserVenuesInput;
  UserVenuesInputFilter: UserVenuesInputFilter;
  UserVenuesSortColumnEnum: UserVenuesSortColumnEnum;
  Users: ResolverTypeWrapper<Users>;
  Venue: ResolverTypeWrapper<Venue>;
  VenueCategory: ResolverTypeWrapper<VenueCategory>;
  VenueDashboardFilterOptionsInput: VenueDashboardFilterOptionsInput;
  VenueDashboardMetric: ResolverTypeWrapper<VenueDashboardMetric>;
  VenueDashboardMetricLabel: VenueDashboardMetricLabel;
  VenueDashboardOrderByEnum: VenueDashboardOrderByEnum;
  VenueDashboardTenderStatus: VenueDashboardTenderStatus;
  VenueDashboardTendersInput: VenueDashboardTendersInput;
  VenueDashboardViews: VenueDashboardViews;
  VenueEdge: ResolverTypeWrapper<VenueEdge>;
  VenueSettings: ResolverTypeWrapper<VenueSettings>;
  Venues: ResolverTypeWrapper<Venues>;
  VolumeRangeOption: VolumeRangeOption;
  Wholesaler: ResolverTypeWrapper<Wholesaler>;
  WholesalerEdge: ResolverTypeWrapper<WholesalerEdge>;
  WholesalerSearchInput: WholesalerSearchInput;
  Wholesalers: ResolverTypeWrapper<Wholesalers>;
  retoolBidsForStatusChange: ResolverTypeWrapper<RetoolBidsForStatusChange>;
  retoolVenuesForStatusChange: ResolverTypeWrapper<RetoolVenuesForStatusChange>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AddBankAccountInput: AddBankAccountInput;
  AddBidComparisonAnalyticInput: AddBidComparisonAnalyticInput;
  AddBidInput: AddBidInput;
  AddBidsToMultipleTendersInput: AddBidsToMultipleTendersInput;
  AddMessageInput: AddMessageInput;
  AddMultipleBidInput: AddMultipleBidInput;
  AddProductCategorySubscriptionInput: AddProductCategorySubscriptionInput;
  AddProductTemplateInput: AddProductTemplateInput;
  AddPromoDealInput: AddPromoDealInput;
  AddTenderDraftInput: AddTenderDraftInput;
  AddTenderInput: AddTenderInput;
  Address: Address;
  AddressByPlaceIdInput: AddressByPlaceIdInput;
  AvatarUrlInput: AvatarUrlInput;
  AwrsDetails: AwrsDetails;
  BankAccount: BankAccount;
  BespokeCheckData: BespokeCheckData;
  Bid: Bid;
  BidRatingFeedbackValue: BidRatingFeedbackValue;
  Bids: Bids;
  BidsFilterOptionsInput: BidsFilterOptionsInput;
  BidsInput: BidsInput;
  BidsSortInput: BidsSortInput;
  BidsViewFiltersInput: BidsViewFiltersInput;
  Boolean: Scalars['Boolean'];
  Brand: Brand;
  BrandDashboardFilterOptionsInput: BrandDashboardFilterOptionsInput;
  BrandDashboardMetric: BrandDashboardMetric;
  BrandDashboardWonBidsSortInput: BrandDashboardWonBidsSortInput;
  BrandSettings: BrandSettings;
  Brands: Brands;
  CancelSubscriptionInput: CancelSubscriptionInput;
  CancelSubscriptionNewInAppInput: CancelSubscriptionNewInAppInput;
  CancelSubscriptionNewRetoolInput: CancelSubscriptionNewRetoolInput;
  ChangeEmailInput: ChangeEmailInput;
  ChooseMultipleTenderWinnersInput: ChooseMultipleTenderWinnersInput;
  ChooseWinnerInput: ChooseWinnerInput;
  CompanyVatDetails: CompanyVatDetails;
  Coordinates: Coordinates;
  CoordinatesInput: CoordinatesInput;
  CreateBankTransferSubscriptionInput: CreateBankTransferSubscriptionInput;
  CreateBespokePaymentLinkInput: CreateBespokePaymentLinkInput;
  CreateStripeCheckoutInput: CreateStripeCheckoutInput;
  CreateStripeCheckoutNewInput: CreateStripeCheckoutNewInput;
  CursorNotifications: CursorNotifications;
  CursorNotificationsInput: CursorNotificationsInput;
  CursorPageInfo: CursorPageInfo;
  CursorPagination: ResolversParentTypes['CursorNotifications'] | ResolversParentTypes['Venues'] | ResolversParentTypes['Wholesalers'];
  CursorPaginationInput: CursorPaginationInput;
  Date: Scalars['Date'];
  DeleteBidInput: DeleteBidInput;
  DeleteProductTemplateInput: DeleteProductTemplateInput;
  Edge: ResolversParentTypes['NotificationEdge'] | ResolversParentTypes['VenueEdge'] | ResolversParentTypes['WholesalerEdge'];
  EditBankAccountInput: EditBankAccountInput;
  EditBidInput: EditBidInput;
  EditBidSupportStatusInput: EditBidSupportStatusInput;
  EditBrandInput: EditBrandInput;
  EditBrandSettingsInput: EditBrandSettingsInput;
  EditProductTemplateInput: EditProductTemplateInput;
  EditPromoDealInput: EditPromoDealInput;
  EditTenderDraftInput: EditTenderDraftInput;
  EditTenderInput: EditTenderInput;
  EditTenderPreferenceInput: EditTenderPreferenceInput;
  EditVenueInput: EditVenueInput;
  EditVenueSettingsInput: EditVenueSettingsInput;
  File: File;
  FileInput: FileInput;
  Filter: Filter;
  FilterOption: FilterOption;
  Float: Scalars['Float'];
  GetAwrsDetailsInput: GetAwrsDetailsInput;
  GetCompanyVatDetailsInput: GetCompanyVatDetailsInput;
  GetDataFromCompanyNumberInput: GetDataFromCompanyNumberInput;
  GoogleAddress: GoogleAddress;
  GoogleAddressInput: GoogleAddressInput;
  GoogleLocationByCoordinates: GoogleLocationByCoordinates;
  HelloInput: HelloInput;
  Int: Scalars['Int'];
  Invoice: Invoice;
  ListingType: ListingType;
  LiveStats: LiveStats;
  LocationByCoordinatesInput: LocationByCoordinatesInput;
  MarkAllNotificationsAsReadInput: MarkAllNotificationsAsReadInput;
  Message: Message;
  MultipleTenderWinnersInput: MultipleTenderWinnersInput;
  Mutation: {};
  MyBidsOption: MyBidsOption;
  MyTendersOption: MyTendersOption;
  Node: ResolversParentTypes['Notification'] | ResolversParentTypes['Venue'] | ResolversParentTypes['Wholesaler'];
  NonSubscribedProductCategory: NonSubscribedProductCategory;
  Notification: Notification;
  NotificationEdge: NotificationEdge;
  Object: Scalars['Object'];
  OffsetNotifications: OffsetNotifications;
  OffsetNotificationsInput: OffsetNotificationsInput;
  OffsetPageInfo: OffsetPageInfo;
  OffsetPaginationInput: OffsetPaginationInput;
  PlaceAutocomplete: PlaceAutocomplete;
  PlacesInput: PlacesInput;
  Point: Point;
  PointInput: PointInput;
  PriceRangeOption: PriceRangeOption;
  ProductCategory: ProductCategory;
  ProductCategorySubscription: ProductCategorySubscription;
  ProductTemplate: ProductTemplate;
  ProductTemplateByIdInput: ProductTemplateByIdInput;
  ProductTemplatesByBrandIdInput: ProductTemplatesByBrandIdInput;
  PromoDeal: PromoDeal;
  PromoDealProductOptions: PromoDealProductOptions;
  PromoDeals: PromoDeals;
  PromoDealsFilterOptionsInput: PromoDealsFilterOptionsInput;
  PromoDealsInput: PromoDealsInput;
  Query: {};
  RateBidInput: RateBidInput;
  RatingFeedbackValue: RatingFeedbackValue;
  RatingOption: RatingOption;
  RegisteredCompany: RegisteredCompany;
  RegisteredCompanyInput: RegisteredCompanyInput;
  RegisteredCompanyWithoutId: RegisteredCompanyWithoutId;
  RemoveProductCategorySubscriptionInput: RemoveProductCategorySubscriptionInput;
  RequestEmailArgument: RequestEmailArgument;
  RequestEmailInput: RequestEmailInput;
  RetoolBrandListFilter: RetoolBrandListFilter;
  RetoolBrandListInput: RetoolBrandListInput;
  RetoolChangeBidStatusInput: RetoolChangeBidStatusInput;
  RetoolChangeTenderAllBidsStatusInput: RetoolChangeTenderAllBidsStatusInput;
  RetoolChangeTenderStatusInput: RetoolChangeTenderStatusInput;
  RetoolUsersFilterInput: RetoolUsersFilterInput;
  RetoolUsersInListInput: RetoolUsersInListInput;
  SampleRequestInput: SampleRequestInput;
  SearchVenueGroupsInput: SearchVenueGroupsInput;
  SearchVenuesInput: SearchVenuesInput;
  SignUpInput: SignUpInput;
  String: Scalars['String'];
  StripeProductCategorySubscription: StripeProductCategorySubscription;
  Subscription: {};
  SuccessFeeInvoice: SuccessFeeInvoice;
  SuccessFeeInvoiceListItem: SuccessFeeInvoiceListItem;
  SupportCalculatorInput: SupportCalculatorInput;
  Tender: Tender;
  TenderDraft: TenderDraft;
  TenderFilterOptionsInput: TenderFilterOptionsInput;
  TenderListFilterOption: TenderListFilterOption;
  TenderListPaginationInput: TenderListPaginationInput;
  TenderPreference: TenderPreference;
  TenderSortInput: TenderSortInput;
  Tenders: Tenders;
  TendersInput: TendersInput;
  TotalSupportFromBrandPerVenue: TotalSupportFromBrandPerVenue;
  UnionTenderInList: ResolversParentTypes['Tender'] | ResolversParentTypes['TenderDraft'];
  UnionTenders: Omit<UnionTenders, 'data'> & { data: Array<ResolversParentTypes['UnionTenderInList']> };
  UpdateCompanyBillingDataInput: UpdateCompanyBillingDataInput;
  UpdateSubscriptionInput: UpdateSubscriptionInput;
  UpgradeProductCategorySubscriptionInput: UpgradeProductCategorySubscriptionInput;
  UpgradeToBespokeData: UpgradeToBespokeData;
  UploadUrlItem: UploadUrlItem;
  User: User;
  UserActionLog: UserActionLog;
  UserActionLogInput: UserActionLogInput;
  UserExistsInput: UserExistsInput;
  UserMeInput: UserMeInput;
  UserVenuesInput: UserVenuesInput;
  UserVenuesInputFilter: UserVenuesInputFilter;
  Users: Users;
  Venue: Venue;
  VenueCategory: VenueCategory;
  VenueDashboardFilterOptionsInput: VenueDashboardFilterOptionsInput;
  VenueDashboardMetric: VenueDashboardMetric;
  VenueDashboardTendersInput: VenueDashboardTendersInput;
  VenueEdge: VenueEdge;
  VenueSettings: VenueSettings;
  Venues: Venues;
  VolumeRangeOption: VolumeRangeOption;
  Wholesaler: Wholesaler;
  WholesalerEdge: WholesalerEdge;
  WholesalerSearchInput: WholesalerSearchInput;
  Wholesalers: Wholesalers;
  retoolBidsForStatusChange: RetoolBidsForStatusChange;
  retoolVenuesForStatusChange: RetoolVenuesForStatusChange;
}>;

export type AnonymousDirectiveArgs = { };

export type AnonymousDirectiveResolver<Result, Parent, ContextType = any, Args = AnonymousDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ConstraintDirectiveArgs = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  exclusiveMax?: Maybe<Scalars['Int']>;
  exclusiveMin?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Int']>;
  maxLength?: Maybe<Scalars['Int']>;
  maxListLength?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  minListLength?: Maybe<Scalars['Int']>;
  multipleOf?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['String']>;
  oneOf?: Maybe<Array<Maybe<Scalars['String']>>>;
  pattern?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  uniqueTypeName?: Maybe<Scalars['String']>;
};

export type ConstraintDirectiveResolver<Result, Parent, ContextType = any, Args = ConstraintDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type HasFeatureDirectiveArgs = {
  feature?: Maybe<Feature>;
};

export type HasFeatureDirectiveResolver<Result, Parent, ContextType = any, Args = HasFeatureDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IsAuthDirectiveArgs = {
  userTypes?: Maybe<Array<UserType>>;
};

export type IsAuthDirectiveResolver<Result, Parent, ContextType = any, Args = IsAuthDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IsRetoolDirectiveArgs = { };

export type IsRetoolDirectiveResolver<Result, Parent, ContextType = any, Args = IsRetoolDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type OnlyForBrandsAndTenderOwnerDirectiveDirectiveArgs = { };

export type OnlyForBrandsAndTenderOwnerDirectiveDirectiveResolver<Result, Parent, ContextType = any, Args = OnlyForBrandsAndTenderOwnerDirectiveDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type OnlyForTenderAndBidOwnerDirectiveDirectiveArgs = { };

export type OnlyForTenderAndBidOwnerDirectiveDirectiveResolver<Result, Parent, ContextType = any, Args = OnlyForTenderAndBidOwnerDirectiveDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type OnlySharedForAllowedCompetitionDirectiveDirectiveArgs = { };

export type OnlySharedForAllowedCompetitionDirectiveDirectiveResolver<Result, Parent, ContextType = any, Args = OnlySharedForAllowedCompetitionDirectiveDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = ResolversObject<{
  administrativeAreLevel2ShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  administrativeAreaLevel1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  administrativeAreaLevel1ShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  administrativeAreaLevel2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<ResolversTypes['Point'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  placeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AwrsDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AwrsDetails'] = ResolversParentTypes['AwrsDetails']> = ResolversObject<{
  awrsCompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsDeregistrationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsPlaceBusiness?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsRegistrationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankAccount'] = ResolversParentTypes['BankAccount']> = ResolversObject<{
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sortCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BespokeCheckDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['BespokeCheckData'] = ResolversParentTypes['BespokeCheckData']> = ResolversObject<{
  pricePerMonth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  successFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bid'] = ResolversParentTypes['Bid']> = ResolversObject<{
  areWholesalersCompatible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  avatarBucketKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageWholesalePrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bidRatingFeedbackValues?: Resolver<Maybe<Array<ResolversTypes['BidRatingFeedbackValue']>>, ParentType, ContextType>;
  brand?: Resolver<Maybe<ResolversTypes['Brand']>, ParentType, ContextType>;
  brandId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  files?: Resolver<Maybe<Array<ResolversTypes['File']>>, ParentType, ContextType>;
  freeStockNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeStockUnitsAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasOtherBidsForThisVenue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnonymous?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAnyWholesalerCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDirectSupplyCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFreeStockProvided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFreeStockReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isListingFeeProvided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isListingFeeReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPosBudgetProvided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPosBudgetReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRatingOutdated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRetroDiscountProvided?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRetroDiscountReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  listingFeeNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  listingFeeTotalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  netPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  otherSupport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  packagingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posBudgetNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posBudgetTotalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productCategory?: Resolver<Maybe<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  productCategoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ratedAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  retroDiscountAmountPerUnit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  retroDiscountNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BidStatus'], ParentType, ContextType>;
  subscriptionTier?: Resolver<ResolversTypes['SubscriptionTier'], ParentType, ContextType>;
  successFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  successFeeInvoiceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supportsFreeStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportsListingFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportsPosBudget?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportsRetroDiscount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tender?: Resolver<ResolversTypes['Tender'], ParentType, ContextType>;
  tenderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSupportValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  volumeUnits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wholesalers?: Resolver<Maybe<Array<ResolversTypes['Wholesaler']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidRatingFeedbackValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['BidRatingFeedbackValue'] = ResolversParentTypes['BidRatingFeedbackValue']> = ResolversObject<{
  ratingFeedbackValueId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bids'] = ResolversParentTypes['Bids']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BrandResolvers<ContextType = any, ParentType extends ResolversParentTypes['Brand'] = ResolversParentTypes['Brand']> = ResolversObject<{
  avatarBucketKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsCompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsDeregistrationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsPlaceBusiness?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsRegistrationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awrsStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  canSeeOtherBids?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  complianceCheckDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  complianceConfirmation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  complianceNextDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  complianceRiskLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  failedPaymentIntentCounter?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasBids?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasProductTemplates?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAnonymousBidAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isEnterpriseSubscriptionRequestSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCategories?: Resolver<Array<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripePaymentLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeProductPriceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stripeSubscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionTier?: Resolver<ResolversTypes['SubscriptionTier'], ParentType, ContextType>;
  successFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vatCompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vatConsultationNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vatCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vatNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vatProcessingDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webPageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BrandDashboardMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrandDashboardMetric'] = ResolversParentTypes['BrandDashboardMetric']> = ResolversObject<{
  label?: Resolver<ResolversTypes['BrandDashboardMetricLabel'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DashboardMetricType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BrandSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BrandSettings'] = ResolversParentTypes['BrandSettings']> = ResolversObject<{
  brandId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isNewTenderInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNewTenderMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRatingProvidedInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRatingProvidedMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTenderUpdatedInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTenderUpdatedMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BrandsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Brands'] = ResolversParentTypes['Brands']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['Brand']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyVatDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyVatDetails'] = ResolversParentTypes['CompanyVatDetails']> = ResolversObject<{
  vatCompanyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vatConsultationNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vatCountryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vatNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vatProcessingDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CoordinatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coordinates'] = ResolversParentTypes['Coordinates']> = ResolversObject<{
  lat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CursorNotificationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CursorNotifications'] = ResolversParentTypes['CursorNotifications']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['NotificationEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['CursorPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unreadTotalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CursorPageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CursorPageInfo'] = ResolversParentTypes['CursorPageInfo']> = ResolversObject<{
  endCursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CursorPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CursorPagination'] = ResolversParentTypes['CursorPagination']> = ResolversObject<{
  __resolveType: TypeResolveFn<'CursorNotifications' | 'Venues' | 'Wholesalers', ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['Edge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['CursorPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
}>;

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type EdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Edge'] = ResolversParentTypes['Edge']> = ResolversObject<{
  __resolveType: TypeResolveFn<'NotificationEdge' | 'VenueEdge' | 'WholesalerEdge', ParentType, ContextType>;
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Node'], ParentType, ContextType>;
}>;

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = ResolversObject<{
  bucketKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  context?: Resolver<ResolversTypes['FileContext'], ParentType, ContextType>;
  downloadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileSizeInKib?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Filter'] = ResolversParentTypes['Filter']> = ResolversObject<{
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['FilterOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FilterOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilterOption'] = ResolversParentTypes['FilterOption']> = ResolversObject<{
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GoogleAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleAddress'] = ResolversParentTypes['GoogleAddress']> = ResolversObject<{
  administrativeAreLevel2ShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  administrativeAreaLevel1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  administrativeAreaLevel1ShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  administrativeAreaLevel2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<ResolversTypes['Coordinates'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  placeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GoogleLocationByCoordinatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleLocationByCoordinates'] = ResolversParentTypes['GoogleLocationByCoordinates']> = ResolversObject<{
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hostedInvoiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pdfUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ListingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListingType'] = ResolversParentTypes['ListingType']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['Region']>, ParentType, ContextType>;
  suggestedListingLengthInMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  suggestedTenderLengthInMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LiveStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveStats'] = ResolversParentTypes['LiveStats']> = ResolversObject<{
  activeBidGrossMerchandiseValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  activeTenders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']> = ResolversObject<{
  bidId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  brand?: Resolver<Maybe<ResolversTypes['Brand']>, ParentType, ContextType>;
  brandId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAtUtc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['Venue']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  addBankAccount?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType, RequireFields<MutationAddBankAccountArgs, 'input'>>;
  addBid?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<MutationAddBidArgs, 'input'>>;
  addBidComparisonAnalytic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddBidComparisonAnalyticArgs, 'input'>>;
  addBidsToMultipleTenders?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<MutationAddBidsToMultipleTendersArgs, 'input'>>;
  addMessage?: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType, RequireFields<MutationAddMessageArgs, 'input'>>;
  addProductCategorySubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddProductCategorySubscriptionArgs, 'input'>>;
  addProductTemplate?: Resolver<ResolversTypes['ProductTemplate'], ParentType, ContextType, RequireFields<MutationAddProductTemplateArgs, 'input'>>;
  addPromoDeal?: Resolver<ResolversTypes['PromoDeal'], ParentType, ContextType, RequireFields<MutationAddPromoDealArgs, 'input'>>;
  addTender?: Resolver<ResolversTypes['Tender'], ParentType, ContextType, RequireFields<MutationAddTenderArgs, 'input'>>;
  addTenderDraft?: Resolver<ResolversTypes['TenderDraft'], ParentType, ContextType, RequireFields<MutationAddTenderDraftArgs, 'input'>>;
  addUserActionLog?: Resolver<ResolversTypes['UserActionLog'], ParentType, ContextType, RequireFields<MutationAddUserActionLogArgs, 'input'>>;
  cancelSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelSubscriptionArgs, 'input'>>;
  cancelSubscriptionNewInApp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelSubscriptionNewInAppArgs, 'input'>>;
  cancelSubscriptionNewRetool?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelSubscriptionNewRetoolArgs, 'input'>>;
  changeEmail?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationChangeEmailArgs, 'input'>>;
  chooseMultipleTenderWinners?: Resolver<Array<ResolversTypes['Tender']>, ParentType, ContextType, RequireFields<MutationChooseMultipleTenderWinnersArgs, 'input'>>;
  chooseWinner?: Resolver<ResolversTypes['Tender'], ParentType, ContextType, RequireFields<MutationChooseWinnerArgs, 'input'>>;
  cleanupSalesDemoData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCleanupSalesDemoDataArgs, 'token'>>;
  confirmChangeEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationConfirmChangeEmailArgs, 'changeEmailToken'>>;
  createBankTransferSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateBankTransferSubscriptionArgs, 'input'>>;
  createBespokePaymentLink?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateBespokePaymentLinkArgs, 'input'>>;
  createDatabaseBackup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateDatabaseBackupArgs, 'token'>>;
  createStripeCheckout?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateStripeCheckoutArgs, 'input'>>;
  createStripeCheckoutNew?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateStripeCheckoutNewArgs, 'input'>>;
  deleteBidById?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBidByIdArgs, 'bidId'>>;
  deleteProductTemplateById?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProductTemplateByIdArgs, 'input'>>;
  deletePromoDealById?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeletePromoDealByIdArgs, 'promoDealId'>>;
  deleteTenderById?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTenderByIdArgs, 'tenderId'>>;
  deleteTenderDraftById?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteTenderDraftByIdArgs, 'tenderDraftId'>>;
  editBankAccount?: Resolver<ResolversTypes['BankAccount'], ParentType, ContextType, RequireFields<MutationEditBankAccountArgs, 'input'>>;
  editBid?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<MutationEditBidArgs, 'input'>>;
  editBidSupportStatus?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<MutationEditBidSupportStatusArgs, 'input'>>;
  editBrand?: Resolver<ResolversTypes['Brand'], ParentType, ContextType, RequireFields<MutationEditBrandArgs, 'input'>>;
  editBrandSettings?: Resolver<ResolversTypes['BrandSettings'], ParentType, ContextType, RequireFields<MutationEditBrandSettingsArgs, 'input'>>;
  editProductTemplate?: Resolver<ResolversTypes['ProductTemplate'], ParentType, ContextType, RequireFields<MutationEditProductTemplateArgs, 'input'>>;
  editPromoDeal?: Resolver<ResolversTypes['PromoDeal'], ParentType, ContextType, RequireFields<MutationEditPromoDealArgs, 'input'>>;
  editTender?: Resolver<ResolversTypes['Tender'], ParentType, ContextType, RequireFields<MutationEditTenderArgs, 'input'>>;
  editTenderDraft?: Resolver<ResolversTypes['TenderDraft'], ParentType, ContextType, RequireFields<MutationEditTenderDraftArgs, 'input'>>;
  editTenderPreference?: Resolver<ResolversTypes['TenderPreference'], ParentType, ContextType, RequireFields<MutationEditTenderPreferenceArgs, 'input'>>;
  editVenue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType, RequireFields<MutationEditVenueArgs, 'input'>>;
  editVenueSettings?: Resolver<ResolversTypes['VenueSettings'], ParentType, ContextType, RequireFields<MutationEditVenueSettingsArgs, 'input'>>;
  generateUploadUrls?: Resolver<Array<ResolversTypes['UploadUrlItem']>, ParentType, ContextType, RequireFields<MutationGenerateUploadUrlsArgs, 'fileNames'>>;
  markAllNotificationsAsRead?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationMarkAllNotificationsAsReadArgs, 'input'>>;
  markNotificationAsRead?: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationMarkNotificationAsReadArgs, 'id'>>;
  rateBid?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<MutationRateBidArgs, 'input'>>;
  removeProductCategorySubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveProductCategorySubscriptionArgs, 'input'>>;
  requestChangeEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestChangeEmailArgs, 'emailToChange'>>;
  requestEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestEmailArgs, 'input'>>;
  requestSalesDemoBids?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<MutationRequestSalesDemoBidsArgs, 'tenderId'>>;
  retoolChangeBidStatus?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<MutationRetoolChangeBidStatusArgs, 'input'>>;
  retoolChangeTenderAllBidsStatus?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<MutationRetoolChangeTenderAllBidsStatusArgs, 'input'>>;
  retoolChangeTenderStatus?: Resolver<ResolversTypes['Tender'], ParentType, ContextType, RequireFields<MutationRetoolChangeTenderStatusArgs, 'input'>>;
  retoolEditBrand?: Resolver<ResolversTypes['Brand'], ParentType, ContextType, RequireFields<MutationRetoolEditBrandArgs, 'input'>>;
  salesDemoSignUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSalesDemoSignUpArgs, 'input'>>;
  sampleRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSampleRequestArgs, 'input'>>;
  sendBankTransferInvoiceNotPaidEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendBankTransferInvoiceNotPaidEmailArgs, 'token'>>;
  sendDailyNewBidsReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendDailyNewBidsReportArgs, 'token'>>;
  sendDailyNewTenderBrandReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendDailyNewTenderBrandReportArgs, 'token'>>;
  sendDailyRatingReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendDailyRatingReportArgs, 'token'>>;
  sendReTenderEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendReTenderEmailArgs, 'token'>>;
  sendSelectWinnerEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendSelectWinnerEmailArgs, 'token'>>;
  sendTenderEndsInTwoDaysEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendTenderEndsInTwoDaysEmailArgs, 'token'>>;
  signInAsUser?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSignInAsUserArgs, 'userId'>>;
  signUp?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSignUpArgs, 'input'>>;
  supportCalculator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSupportCalculatorArgs, 'input'>>;
  syncTenderStatuses?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSyncTenderStatusesArgs, 'token'>>;
  updateCompanyBillingData?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateCompanyBillingDataArgs, 'input'>>;
  updateSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateSubscriptionArgs, 'input'>>;
  upgradeProductCategorySubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpgradeProductCategorySubscriptionArgs, 'input'>>;
  upgradeToBespoke?: Resolver<ResolversTypes['UpgradeToBespokeData'], ParentType, ContextType, RequireFields<MutationUpgradeToBespokeArgs, 'token'>>;
}>;

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Notification' | 'Venue' | 'Wholesaler', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
}>;

export type NonSubscribedProductCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonSubscribedProductCategory'] = ResolversParentTypes['NonSubscribedProductCategory']> = ResolversObject<{
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfProductsInCategory?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stripeProductId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = ResolversObject<{
  bid?: Resolver<Maybe<ResolversTypes['Bid']>, ParentType, ContextType>;
  bidId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brand?: Resolver<Maybe<ResolversTypes['Brand']>, ParentType, ContextType>;
  brandId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['NotificationCategory'], ParentType, ContextType>;
  createdAtUtc?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  fromId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fromType?: Resolver<Maybe<ResolversTypes['NotificationTargetType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tender?: Resolver<Maybe<ResolversTypes['Tender']>, ParentType, ContextType>;
  tenderId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  toId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  toType?: Resolver<ResolversTypes['NotificationTargetType'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  updatedAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  venue?: Resolver<Maybe<ResolversTypes['Venue']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationEdge'] = ResolversParentTypes['NotificationEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Notification'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface ObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Object'], any> {
  name: 'Object';
}

export type OffsetNotificationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OffsetNotifications'] = ResolversParentTypes['OffsetNotifications']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unreadTotalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OffsetPageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OffsetPageInfo'] = ResolversParentTypes['OffsetPageInfo']> = ResolversObject<{
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPrevPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PlaceAutocompleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlaceAutocomplete'] = ResolversParentTypes['PlaceAutocomplete']> = ResolversObject<{
  placeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  placeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Point'] = ResolversParentTypes['Point']> = ResolversObject<{
  x?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductCategory'] = ResolversParentTypes['ProductCategory']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productCategorySubscriptions?: Resolver<Maybe<Array<ResolversTypes['ProductCategorySubscription']>>, ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['Region']>, ParentType, ContextType>;
  unitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductCategorySubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductCategorySubscription'] = ResolversParentTypes['ProductCategorySubscription']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['ProductCategorySubscriptionType'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stripeProductId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductTemplate'] = ResolversParentTypes['ProductTemplate']> = ResolversObject<{
  avatarBucketKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageWholesalePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  brand?: Resolver<ResolversTypes['Brand'], ParentType, ContextType>;
  brandId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  files?: Resolver<Maybe<Array<ResolversTypes['File']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnyWholesalerCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDirectSupplyCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  packagingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productCategory?: Resolver<ResolversTypes['ProductCategory'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  volumeUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wholesalers?: Resolver<Array<ResolversTypes['Wholesaler']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromoDealResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoDeal'] = ResolversParentTypes['PromoDeal']> = ResolversObject<{
  avatarBucketKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  averageWholesalePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  brand?: Resolver<Maybe<ResolversTypes['Brand']>, ParentType, ContextType>;
  brandId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAtUtc?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  files?: Resolver<Maybe<Array<ResolversTypes['File']>>, ParentType, ContextType>;
  freeStockNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeStockUnitsAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnyWholesalerCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDirectSupplyCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  listingFeeNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  listingFeeTotalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  otherSupport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  packagingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posBudgetNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posBudgetTotalAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productCategory?: Resolver<Maybe<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  retroDiscountAmountPerUnit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  retroDiscountNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PromoDealStatus'], ParentType, ContextType>;
  supportsFreeStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportsListingFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportsPosBudget?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportsRetroDiscount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unitType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  volumeUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wholesalers?: Resolver<Array<ResolversTypes['Wholesaler']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromoDealProductOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoDealProductOptions'] = ResolversParentTypes['PromoDealProductOptions']> = ResolversObject<{
  productCategories?: Resolver<Array<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  productPackaging?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  productUnits?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  wholesalers?: Resolver<Array<ResolversTypes['Wholesaler']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PromoDealsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoDeals'] = ResolversParentTypes['PromoDeals']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['PromoDeal']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  addressByPlaceId?: Resolver<ResolversTypes['GoogleAddress'], ParentType, ContextType, RequireFields<QueryAddressByPlaceIdArgs, 'input'>>;
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryAvatarUrlArgs, 'input'>>;
  bankAccount?: Resolver<Maybe<ResolversTypes['BankAccount']>, ParentType, ContextType>;
  bespokeTokenCheck?: Resolver<ResolversTypes['BespokeCheckData'], ParentType, ContextType, RequireFields<QueryBespokeTokenCheckArgs, 'token'>>;
  bidById?: Resolver<ResolversTypes['Bid'], ParentType, ContextType, RequireFields<QueryBidByIdArgs, 'bidId'>>;
  bids?: Resolver<ResolversTypes['Bids'], ParentType, ContextType, RequireFields<QueryBidsArgs, 'input'>>;
  bidsViewFilterOptions?: Resolver<Array<ResolversTypes['Filter']>, ParentType, ContextType, RequireFields<QueryBidsViewFilterOptionsArgs, 'input'>>;
  brandById?: Resolver<ResolversTypes['Brand'], ParentType, ContextType, RequireFields<QueryBrandByIdArgs, 'brandId'>>;
  brandDashboardMetricsByBrandId?: Resolver<Array<ResolversTypes['BrandDashboardMetric']>, ParentType, ContextType, RequireFields<QueryBrandDashboardMetricsByBrandIdArgs, 'brandId'>>;
  brandSettingsByBrandId?: Resolver<ResolversTypes['BrandSettings'], ParentType, ContextType, RequireFields<QueryBrandSettingsByBrandIdArgs, 'brandId'>>;
  canCreateBid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cursorNotifications?: Resolver<ResolversTypes['CursorNotifications'], ParentType, ContextType, RequireFields<QueryCursorNotificationsArgs, 'input'>>;
  getAwrsDetails?: Resolver<ResolversTypes['AwrsDetails'], ParentType, ContextType, RequireFields<QueryGetAwrsDetailsArgs, 'input'>>;
  getCompanyVatDetails?: Resolver<ResolversTypes['CompanyVatDetails'], ParentType, ContextType, RequireFields<QueryGetCompanyVatDetailsArgs, 'input'>>;
  getDataFromCompanyNumber?: Resolver<ResolversTypes['RegisteredCompanyWithoutId'], ParentType, ContextType, RequireFields<QueryGetDataFromCompanyNumberArgs, 'input'>>;
  getLiveStats?: Resolver<ResolversTypes['LiveStats'], ParentType, ContextType>;
  hasActiveSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hello?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryHelloArgs, 'input'>>;
  invoices?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QueryInvoicesArgs, 'stripeCustomerId'>>;
  listingTypes?: Resolver<Array<ResolversTypes['ListingType']>, ParentType, ContextType>;
  locationByCoordinates?: Resolver<ResolversTypes['GoogleLocationByCoordinates'], ParentType, ContextType, RequireFields<QueryLocationByCoordinatesArgs, 'input'>>;
  messagesByBidId?: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType, RequireFields<QueryMessagesByBidIdArgs, 'bidId'>>;
  nonSubscribedProductCategories?: Resolver<Array<ResolversTypes['NonSubscribedProductCategory']>, ParentType, ContextType, RequireFields<QueryNonSubscribedProductCategoriesArgs, 'stripeSubscriptionId'>>;
  offsetNotifications?: Resolver<ResolversTypes['OffsetNotifications'], ParentType, ContextType, RequireFields<QueryOffsetNotificationsArgs, 'input'>>;
  places?: Resolver<Array<ResolversTypes['PlaceAutocomplete']>, ParentType, ContextType, RequireFields<QueryPlacesArgs, 'input'>>;
  previousBid?: Resolver<Maybe<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<QueryPreviousBidArgs, 'brandId'>>;
  productCategories?: Resolver<Array<ResolversTypes['ProductCategory']>, ParentType, ContextType>;
  productCategorySubscriptions?: Resolver<Array<ResolversTypes['ProductCategorySubscription']>, ParentType, ContextType>;
  productPackaging?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  productTemplateById?: Resolver<ResolversTypes['ProductTemplate'], ParentType, ContextType, RequireFields<QueryProductTemplateByIdArgs, 'input'>>;
  productTemplatesByBrandId?: Resolver<Array<ResolversTypes['ProductTemplate']>, ParentType, ContextType, RequireFields<QueryProductTemplatesByBrandIdArgs, 'input'>>;
  productUnits?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  promoDealProductOptions?: Resolver<ResolversTypes['PromoDealProductOptions'], ParentType, ContextType, RequireFields<QueryPromoDealProductOptionsArgs, 'region'>>;
  promoDeals?: Resolver<ResolversTypes['PromoDeals'], ParentType, ContextType, RequireFields<QueryPromoDealsArgs, 'input'>>;
  ratingFeedbackValues?: Resolver<Array<ResolversTypes['RatingFeedbackValue']>, ParentType, ContextType>;
  ratingOptions?: Resolver<Array<ResolversTypes['RatingOption']>, ParentType, ContextType>;
  retoolBidsForTenderAndBidStatusChange?: Resolver<Array<ResolversTypes['retoolBidsForStatusChange']>, ParentType, ContextType, RequireFields<QueryRetoolBidsForTenderAndBidStatusChangeArgs, 'tenderId'>>;
  retoolBrandById?: Resolver<ResolversTypes['Brand'], ParentType, ContextType, RequireFields<QueryRetoolBrandByIdArgs, 'brandId'>>;
  retoolBrandInList?: Resolver<ResolversTypes['Brands'], ParentType, ContextType, RequireFields<QueryRetoolBrandInListArgs, 'input'>>;
  retoolUsersInList?: Resolver<ResolversTypes['Users'], ParentType, ContextType, RequireFields<QueryRetoolUsersInListArgs, 'input'>>;
  retoolVenuesForTenderAndBidStatusChange?: Resolver<Array<ResolversTypes['retoolVenuesForStatusChange']>, ParentType, ContextType>;
  searchVenueGroups?: Resolver<ResolversTypes['Venues'], ParentType, ContextType, RequireFields<QuerySearchVenueGroupsArgs, 'input'>>;
  searchVenues?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType, RequireFields<QuerySearchVenuesArgs, 'input'>>;
  searchWholesalers?: Resolver<ResolversTypes['Wholesalers'], ParentType, ContextType, RequireFields<QuerySearchWholesalersArgs, 'input'>>;
  subscription?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType, RequireFields<QuerySubscriptionArgs, 'stripeSubscriptionId'>>;
  subscriptions?: Resolver<Array<ResolversTypes['StripeProductCategorySubscription']>, ParentType, ContextType, RequireFields<QuerySubscriptionsArgs, 'stripeSubscriptionId'>>;
  successFeeInvoiceById?: Resolver<ResolversTypes['SuccessFeeInvoice'], ParentType, ContextType, RequireFields<QuerySuccessFeeInvoiceByIdArgs, 'invoiceId'>>;
  successFeeInvoiceList?: Resolver<Array<ResolversTypes['SuccessFeeInvoiceListItem']>, ParentType, ContextType, RequireFields<QuerySuccessFeeInvoiceListArgs, 'userId'>>;
  tenderById?: Resolver<ResolversTypes['Tender'], ParentType, ContextType, RequireFields<QueryTenderByIdArgs, 'tenderId'>>;
  tenderDraftById?: Resolver<ResolversTypes['TenderDraft'], ParentType, ContextType, RequireFields<QueryTenderDraftByIdArgs, 'tenderDraftId'>>;
  tenderList?: Resolver<ResolversTypes['Tenders'], ParentType, ContextType, RequireFields<QueryTenderListArgs, 'input'>>;
  tenderListFilterOptions?: Resolver<Array<ResolversTypes['Filter']>, ParentType, ContextType>;
  tenderPreferenceByVenueId?: Resolver<ResolversTypes['TenderPreference'], ParentType, ContextType, RequireFields<QueryTenderPreferenceByVenueIdArgs, 'venueId'>>;
  topTendersBidsCountMinBoundary?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSupportFromBrandPerActiveVenue?: Resolver<ResolversTypes['TotalSupportFromBrandPerVenue'], ParentType, ContextType, RequireFields<QueryTotalSupportFromBrandPerActiveVenueArgs, 'brandId' | 'venueId'>>;
  userExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryUserExistsArgs, 'input'>>;
  userMe?: Resolver<ResolversTypes['User'], ParentType, ContextType, Partial<QueryUserMeArgs>>;
  userVenues?: Resolver<ResolversTypes['Venues'], ParentType, ContextType, RequireFields<QueryUserVenuesArgs, 'input'>>;
  userVenuesByName?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType, RequireFields<QueryUserVenuesByNameArgs, 'venueName'>>;
  venueById?: Resolver<ResolversTypes['Venue'], ParentType, ContextType, RequireFields<QueryVenueByIdArgs, 'venueId'>>;
  venueCategories?: Resolver<Array<ResolversTypes['VenueCategory']>, ParentType, ContextType, Partial<QueryVenueCategoriesArgs>>;
  venueDashboardMetricsByVenueId?: Resolver<Array<ResolversTypes['VenueDashboardMetric']>, ParentType, ContextType, RequireFields<QueryVenueDashboardMetricsByVenueIdArgs, 'venueId'>>;
  venueDashboardTenders?: Resolver<ResolversTypes['UnionTenders'], ParentType, ContextType, RequireFields<QueryVenueDashboardTendersArgs, 'input'>>;
  venueSettingsByVenueId?: Resolver<ResolversTypes['VenueSettings'], ParentType, ContextType, RequireFields<QueryVenueSettingsByVenueIdArgs, 'venueId'>>;
  winningBidsByTenderId?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<QueryWinningBidsByTenderIdArgs, 'tenderId'>>;
}>;

export type RatingFeedbackValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingFeedbackValue'] = ResolversParentTypes['RatingFeedbackValue']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RatingOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatingOption'] = ResolversParentTypes['RatingOption']> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['RatingOptionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegisteredCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredCompany'] = ResolversParentTypes['RegisteredCompany']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  addressId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfCreation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstAddressLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondAddressLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegisteredCompanyWithoutIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisteredCompanyWithoutId'] = ResolversParentTypes['RegisteredCompanyWithoutId']> = ResolversObject<{
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfCreation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstAddressLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondAddressLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StripeProductCategorySubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeProductCategorySubscription'] = ResolversParentTypes['StripeProductCategorySubscription']> = ResolversObject<{
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfProductsInCategory?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['ProductCategorySubscriptionType'], ParentType, ContextType>;
  pricePerMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productCategorySubscriptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stripeProductId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  amount?: SubscriptionResolver<Maybe<ResolversTypes['Int']>, "amount", ParentType, ContextType>;
  createdAtUtc?: SubscriptionResolver<ResolversTypes['Int'], "createdAtUtc", ParentType, ContextType>;
  currentPeriodEnd?: SubscriptionResolver<ResolversTypes['Int'], "currentPeriodEnd", ParentType, ContextType>;
  currentPeriodStart?: SubscriptionResolver<ResolversTypes['Int'], "currentPeriodStart", ParentType, ContextType>;
  customerId?: SubscriptionResolver<ResolversTypes['String'], "customerId", ParentType, ContextType>;
  id?: SubscriptionResolver<ResolversTypes['String'], "id", ParentType, ContextType>;
  isCanceled?: SubscriptionResolver<ResolversTypes['Boolean'], "isCanceled", ParentType, ContextType>;
  isExpired?: SubscriptionResolver<ResolversTypes['Boolean'], "isExpired", ParentType, ContextType>;
  subscriptionTier?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionTier']>, "subscriptionTier", ParentType, ContextType>;
  successFee?: SubscriptionResolver<Maybe<ResolversTypes['Float']>, "successFee", ParentType, ContextType>;
}>;

export type SuccessFeeInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuccessFeeInvoice'] = ResolversParentTypes['SuccessFeeInvoice']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pdfUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SuccessFeeInvoiceListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuccessFeeInvoiceListItem'] = ResolversParentTypes['SuccessFeeInvoiceListItem']> = ResolversObject<{
  averageWholesaleBidPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bidUnitType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bidVolumeUnits?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  estimatedVolume?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagingType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pdfUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  successFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tenderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenderUnitType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenderVolumeUnits?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  venueName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tender'] = ResolversParentTypes['Tender']> = ResolversObject<{
  createdAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimatedVolume?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feedback?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  files?: Resolver<Maybe<Array<ResolversTypes['File']>>, ParentType, ContextType>;
  hasBrandsBid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hostingChargeInvoiceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnyWholesalerCompatible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDirectSupplyCompatible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  listingEndsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  listingStartsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  listingType?: Resolver<ResolversTypes['ListingType'], ParentType, ContextType>;
  listingTypeId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCategory?: Resolver<ResolversTypes['ProductCategory'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receivedSupportStatus?: Resolver<Maybe<ResolversTypes['TenderSupportStatus']>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TenderStatus'], ParentType, ContextType>;
  supportDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supportsFreeStock?: Resolver<Maybe<ResolversTypes['TenderSupportOption']>, ParentType, ContextType>;
  supportsListingFee?: Resolver<Maybe<ResolversTypes['TenderSupportOption']>, ParentType, ContextType>;
  supportsPosBudget?: Resolver<Maybe<ResolversTypes['TenderSupportOption']>, ParentType, ContextType>;
  supportsRetroDiscount?: Resolver<Maybe<ResolversTypes['TenderSupportOption']>, ParentType, ContextType>;
  tenderDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenderEndsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  tenderStartsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  totalReceivedBids?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  volumeDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volumeUnits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wholesalers?: Resolver<Maybe<Array<ResolversTypes['Wholesaler']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenderDraftResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenderDraft'] = ResolversParentTypes['TenderDraft']> = ResolversObject<{
  createdAtUtc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  estimatedVolume?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnyWholesalerCompatible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDirectSupplyCompatible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  listingEndsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  listingStartsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  listingType?: Resolver<ResolversTypes['ListingType'], ParentType, ContextType>;
  listingTypeId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCategory?: Resolver<ResolversTypes['ProductCategory'], ParentType, ContextType>;
  productCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  supportDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supportsFreeStock?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  supportsListingFee?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  supportsPosBudget?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  supportsRetroDiscount?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  tenderDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenderEndsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  tenderStartsAtUtc?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  unitType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  volumeDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volumeUnits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wholesalers?: Resolver<Array<ResolversTypes['Wholesaler']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenderListFilterOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenderListFilterOption'] = ResolversParentTypes['TenderListFilterOption']> = ResolversObject<{
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['FilterOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenderPreferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenderPreference'] = ResolversParentTypes['TenderPreference']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isAnyWholesalerCompatible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDirectSupplyCompatible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supportsFreeStock?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  supportsListingFee?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  supportsPosBudget?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  supportsRetroDiscount?: Resolver<ResolversTypes['TenderSupportOption'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wholesalers?: Resolver<Array<ResolversTypes['Wholesaler']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TendersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tenders'] = ResolversParentTypes['Tenders']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['Tender']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TotalSupportFromBrandPerVenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['TotalSupportFromBrandPerVenue'] = ResolversParentTypes['TotalSupportFromBrandPerVenue']> = ResolversObject<{
  totalSupport?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTenders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnionTenderInListResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnionTenderInList'] = ResolversParentTypes['UnionTenderInList']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Tender' | 'TenderDraft', ParentType, ContextType>;
}>;

export type UnionTendersResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnionTenders'] = ResolversParentTypes['UnionTenders']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['UnionTenderInList']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpgradeToBespokeDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpgradeToBespokeData'] = ResolversParentTypes['UpgradeToBespokeData']> = ResolversObject<{
  needsRedirect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  redirectUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wasSuccessfullyUpdated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploadUrlItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadUrlItem'] = ResolversParentTypes['UploadUrlItem']> = ResolversObject<{
  bucketKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  activeVenue?: Resolver<Maybe<ResolversTypes['Venue']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  addressId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  brands?: Resolver<Array<ResolversTypes['Brand']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firebaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstFourVenues?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isSalesDemo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  registeredCompany?: Resolver<Maybe<ResolversTypes['RegisteredCompany']>, ParentType, ContextType>;
  tradingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  tradingName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  venues?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserActionLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserActionLog'] = ResolversParentTypes['UserActionLog']> = ResolversObject<{
  additionalData?: Resolver<Maybe<ResolversTypes['Object']>, ParentType, ContextType>;
  eventName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['OffsetPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VenueResolvers<ContextType = any, ParentType extends ResolversParentTypes['Venue'] = ResolversParentTypes['Venue']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  addressId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  avatarBucketKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  canCreateTender?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasTenders?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instagramUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenderPreference?: Resolver<ResolversTypes['TenderPreference'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  venueCategory?: Resolver<Maybe<ResolversTypes['VenueCategory']>, ParentType, ContextType>;
  venueCategoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  venueCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webPageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VenueCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['VenueCategory'] = ResolversParentTypes['VenueCategory']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venues?: Resolver<Array<ResolversTypes['Venue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VenueDashboardMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['VenueDashboardMetric'] = ResolversParentTypes['VenueDashboardMetric']> = ResolversObject<{
  label?: Resolver<ResolversTypes['VenueDashboardMetricLabel'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['DashboardMetricType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VenueEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VenueEdge'] = ResolversParentTypes['VenueEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Venue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VenueSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VenueSettings'] = ResolversParentTypes['VenueSettings']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isBidUpdatedInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isBidUpdatedMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMyTenderEndedInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMyTenderEndedMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNewBidsInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNewBidsMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNewTenderInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNewTenderMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isReTenderInAppNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isReTenderMailNotificationEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type VenuesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Venues'] = ResolversParentTypes['Venues']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['VenueEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['CursorPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WholesalerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Wholesaler'] = ResolversParentTypes['Wholesaler']> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['Region'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WholesalerEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WholesalerEdge'] = ResolversParentTypes['WholesalerEdge']> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Wholesaler'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WholesalersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Wholesalers'] = ResolversParentTypes['Wholesalers']> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['WholesalerEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['CursorPageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RetoolBidsForStatusChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['retoolBidsForStatusChange'] = ResolversParentTypes['retoolBidsForStatusChange']> = ResolversObject<{
  bidId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bidStatus?: Resolver<ResolversTypes['BidStatus'], ParentType, ContextType>;
  brandName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  brandUserId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenderStatus?: Resolver<ResolversTypes['TenderStatus'], ParentType, ContextType>;
  venueName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venueUserId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RetoolVenuesForStatusChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['retoolVenuesForStatusChange'] = ResolversParentTypes['retoolVenuesForStatusChange']> = ResolversObject<{
  tenderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenderStatus?: Resolver<ResolversTypes['TenderStatus'], ParentType, ContextType>;
  venueName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venueUserId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Address?: AddressResolvers<ContextType>;
  AwrsDetails?: AwrsDetailsResolvers<ContextType>;
  BankAccount?: BankAccountResolvers<ContextType>;
  BespokeCheckData?: BespokeCheckDataResolvers<ContextType>;
  Bid?: BidResolvers<ContextType>;
  BidRatingFeedbackValue?: BidRatingFeedbackValueResolvers<ContextType>;
  Bids?: BidsResolvers<ContextType>;
  Brand?: BrandResolvers<ContextType>;
  BrandDashboardMetric?: BrandDashboardMetricResolvers<ContextType>;
  BrandSettings?: BrandSettingsResolvers<ContextType>;
  Brands?: BrandsResolvers<ContextType>;
  CompanyVatDetails?: CompanyVatDetailsResolvers<ContextType>;
  Coordinates?: CoordinatesResolvers<ContextType>;
  CursorNotifications?: CursorNotificationsResolvers<ContextType>;
  CursorPageInfo?: CursorPageInfoResolvers<ContextType>;
  CursorPagination?: CursorPaginationResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Edge?: EdgeResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  Filter?: FilterResolvers<ContextType>;
  FilterOption?: FilterOptionResolvers<ContextType>;
  GoogleAddress?: GoogleAddressResolvers<ContextType>;
  GoogleLocationByCoordinates?: GoogleLocationByCoordinatesResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  ListingType?: ListingTypeResolvers<ContextType>;
  LiveStats?: LiveStatsResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  NonSubscribedProductCategory?: NonSubscribedProductCategoryResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationEdge?: NotificationEdgeResolvers<ContextType>;
  Object?: GraphQLScalarType;
  OffsetNotifications?: OffsetNotificationsResolvers<ContextType>;
  OffsetPageInfo?: OffsetPageInfoResolvers<ContextType>;
  PlaceAutocomplete?: PlaceAutocompleteResolvers<ContextType>;
  Point?: PointResolvers<ContextType>;
  ProductCategory?: ProductCategoryResolvers<ContextType>;
  ProductCategorySubscription?: ProductCategorySubscriptionResolvers<ContextType>;
  ProductTemplate?: ProductTemplateResolvers<ContextType>;
  PromoDeal?: PromoDealResolvers<ContextType>;
  PromoDealProductOptions?: PromoDealProductOptionsResolvers<ContextType>;
  PromoDeals?: PromoDealsResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RatingFeedbackValue?: RatingFeedbackValueResolvers<ContextType>;
  RatingOption?: RatingOptionResolvers<ContextType>;
  RegisteredCompany?: RegisteredCompanyResolvers<ContextType>;
  RegisteredCompanyWithoutId?: RegisteredCompanyWithoutIdResolvers<ContextType>;
  StripeProductCategorySubscription?: StripeProductCategorySubscriptionResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SuccessFeeInvoice?: SuccessFeeInvoiceResolvers<ContextType>;
  SuccessFeeInvoiceListItem?: SuccessFeeInvoiceListItemResolvers<ContextType>;
  Tender?: TenderResolvers<ContextType>;
  TenderDraft?: TenderDraftResolvers<ContextType>;
  TenderListFilterOption?: TenderListFilterOptionResolvers<ContextType>;
  TenderPreference?: TenderPreferenceResolvers<ContextType>;
  Tenders?: TendersResolvers<ContextType>;
  TotalSupportFromBrandPerVenue?: TotalSupportFromBrandPerVenueResolvers<ContextType>;
  UnionTenderInList?: UnionTenderInListResolvers<ContextType>;
  UnionTenders?: UnionTendersResolvers<ContextType>;
  UpgradeToBespokeData?: UpgradeToBespokeDataResolvers<ContextType>;
  UploadUrlItem?: UploadUrlItemResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserActionLog?: UserActionLogResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  Venue?: VenueResolvers<ContextType>;
  VenueCategory?: VenueCategoryResolvers<ContextType>;
  VenueDashboardMetric?: VenueDashboardMetricResolvers<ContextType>;
  VenueEdge?: VenueEdgeResolvers<ContextType>;
  VenueSettings?: VenueSettingsResolvers<ContextType>;
  Venues?: VenuesResolvers<ContextType>;
  Wholesaler?: WholesalerResolvers<ContextType>;
  WholesalerEdge?: WholesalerEdgeResolvers<ContextType>;
  Wholesalers?: WholesalersResolvers<ContextType>;
  retoolBidsForStatusChange?: RetoolBidsForStatusChangeResolvers<ContextType>;
  retoolVenuesForStatusChange?: RetoolVenuesForStatusChangeResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = any> = ResolversObject<{
  anonymous?: AnonymousDirectiveResolver<any, any, ContextType>;
  constraint?: ConstraintDirectiveResolver<any, any, ContextType>;
  hasFeature?: HasFeatureDirectiveResolver<any, any, ContextType>;
  isAuth?: IsAuthDirectiveResolver<any, any, ContextType>;
  isRetool?: IsRetoolDirectiveResolver<any, any, ContextType>;
  onlyForBrandsAndTenderOwnerDirective?: OnlyForBrandsAndTenderOwnerDirectiveDirectiveResolver<any, any, ContextType>;
  onlyForTenderAndBidOwnerDirective?: OnlyForTenderAndBidOwnerDirectiveDirectiveResolver<any, any, ContextType>;
  onlySharedForAllowedCompetitionDirective?: OnlySharedForAllowedCompetitionDirectiveDirectiveResolver<any, any, ContextType>;
}>;
